import { gql} from "@apollo/client";

  const LOGIN_USER = gql`
    query login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        created_at
        email
        firstName
        id
        isActive
        lastLogin
        lastName
        phone
        token
        updated_at
      }
    }
  `

export default LOGIN_USER;
