import { gql } from "@apollo/client/core";

const GET_STARRED = gql`
  query ($page_limit: Int!, $page_number: Int!) {
    getStarred(page_limit: $page_limit, page_number: $page_number) {
      starred {
        firstName
        lastName
        profilePicture
        username
      }
    }
  }
`;

export default GET_STARRED;
