//router
import { NavLink } from "react-router-dom";
//recoil
// import { useRecoilState } from "recoil";
// import UserData from "../../recoil/UserRecoil";
//api
import { useQuery } from "@apollo/client";
import GET_USER_THREADS from "../../apis/messaging/getUserThreads";

//components
import { Avatar } from "@chakra-ui/react";
//utils
import truncateText from "../../utils/truncateText";

export const MessagePreview = () => {

  //use this user to get authId and pass it to the query below
  // const [user] = useRecoilState(UserData);
  const { data, loading, error } = useQuery(GET_USER_THREADS, {
    variables: {
      id: "663f67ea-053d-4fb1-94da-de66fd1ef2a4",
    },
    pollInterval: 5000,
  });

  if (loading) console.log(loading)
  if (error) console.log(error)
  return data && data.getUserThreads.map((thread, index) => {
    return (

      <div className="message-preview" key={index}>
        <NavLink to={`/chat/r/${thread.participantsInfo[0].id}`} >
          <Avatar
            src={thread.participantsInfo[0].profilePicture}
            size="md" />
          <span>
            <h2>{thread.participantsInfo[0].firstName}</h2>
            <p>{truncateText(thread.tailMessage.value, 50)}</p>
          </span>
        </NavLink>
      </div>

    );
  })


};
