import { nav } from "../../components";
import { Outlet} from "react-router-dom";

const SearchContainer = (props) => {

  return (
    <div className="search-container">
      <Outlet />
      <nav.BottomNav />
    </div>
  );
};

export default SearchContainer;
