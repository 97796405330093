import { gql } from "@apollo/client";
const GET_POST = gql`
query($postId: String!){
  getPost(postId: $postId){
    audioMediaItem,
    authId,
    commentOption,
    content, 
    created_at,
    edited,
    hashTags,
    imageMediaItems,
    like{
      authId
    }
    location,
    mentionList,
    nComments,
    nLikes,
    postId,
    postSlug,
    profile{
      firstName,
      lastName,
      location,
      profileSlug,
      username,
      verified,
      profilePicture 
    },
    videoMediaItem,
    vote{
      authId
    }
  }
}
`;

export default GET_POST;
