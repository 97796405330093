import { atom } from "recoil";

const Username = atom({
    key: "username",
    default: "",
  });

  export const UsernameUpdateState = atom({
    key: "usernameUpdateState",
    default: false,
  });

  export default Username;