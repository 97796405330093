import { Button } from "@chakra-ui/react";

export const StarButton = () => {
  return (
    <Button
      className="edit-profile-btn reach-btn"
      variant="outline"
      size="sm"
      //   onClick={reachUserHandler}
      //   isLoading={loading? true: false}
    >
      Star
    </Button>
  );
};
