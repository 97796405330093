import { gql } from "@apollo/client";
const SAVE_POST = gql`
  mutation ($postId: String!) {
    savePost(postId: $postId) {
      authId
    }
  }
`;

export default SAVE_POST;
