//components
import { util, skeletons } from ".."

//utils
import truncateText from "../../utils/truncateText";
//api
import { useQuery } from "@apollo/client";
import SUGGEST_USER from "../../apis/profile/getSuggestUsers"
export const ReachSuggestionContainer = () => {
      const { loading: loadingSuggest, data: dataSuggest } = useQuery(SUGGEST_USER);


      return (
            <div className="reach-suggestion-container">
                  <header>
                        <h2>Reach Suggested for</h2>
                  </header>
                  <div className="wrapper">
                        {loadingSuggest ? <skeletons.SimpleLoader amount={10} /> : dataSuggest && dataSuggest.suggestUser.map((user, index) => {

                              return (
                                    <>
                                          <util.ReachSuggestion
                                                index={index}
                                                photo={user.profilePicture}
                                                pureName={user.username}
                                                name={truncateText(user.username, 10)}
                                                info="New to reach me"
                                                authId={user.authId}
                                          />
                                    </>
                              )
                        })}

                  </div>
            </div>
      )
}