const DoubleImage = ({ images }) => {
      return (
            <div className="post-content__double-image" >
                  {
                        images.map((image, index) => {
                              return (
                                    <div key={index}>
                                          <img
                                                src={image}
                                                alt="post"
                                          />
                                    </div>
                              )
                        })
                  }
            </div>
      )
}

export default DoubleImage;