//router
import { Link } from "react-router-dom";
//api
import { useQuery } from "@apollo/client";
import GET_REACHINGS from "../../../apis/profile/getReachings";
//components
import { util, alert } from "../../../components";
//assets
import spinner from "../../../assets/spinner.svg";

const Reaching = () => {
  //ap
  const { loading, error, data } = useQuery(GET_REACHINGS, {
    variables: {
      page_limit: 10,
      page_number: 1,
    },
  });

  if (loading) {
    return (
      <div className="load-spinner">
        <img src={spinner} alt="spinner" />
      </div>
    );
  }

  return (
    <div className="reaching-container">
      {error && <alert.ErrorToast errorMessage="Failed to Fetch" />}
      {data &&
        data.getReachings.map((reaching) => {
          return (
            <Link
              to={`/u/${reaching.reaching.username}`}
              key={reaching.reaching.authId}
            >
              <util.ReachingUser
                username={reaching.reaching.username}
                fullName={`${reaching.reaching.firstName} ${reaching.reaching.lastName}`}
                bio="Life na beans, shebi i don tell you before naaa"
                photo="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
              />
            </Link>
          );
        })}
    </div>
  );
};

export default Reaching;
