import { Button } from "@chakra-ui/react";

const EmptyChat = () => {
  return (
    <div className="empty-chat">
      <div className="empty-chat-container">
        <svg
          width="40"
          height="40"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_3244_4531"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0.000488281H19.499V19.4995H0V0.000488281Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_3244_4531)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.80494 11.818L12.4619 17.751C12.6219 18.011 12.8719 18.008 12.9729 17.994C13.0739 17.98 13.3169 17.918 13.4049 17.623L17.9779 2.17802C18.0579 1.90502 17.9109 1.71902 17.8449 1.65302C17.7809 1.58702 17.5979 1.44602 17.3329 1.52102L1.87695 6.04702C1.58394 6.13302 1.51994 6.37902 1.50594 6.48002C1.49194 6.58302 1.48794 6.83802 1.74695 7.00102L7.74794 10.754L13.0499 5.39602C13.3409 5.10202 13.8159 5.09902 14.1109 5.39002C14.4059 5.68102 14.4079 6.15702 14.1169 6.45102L8.80494 11.818ZM12.8949 19.5C12.1989 19.5 11.5609 19.146 11.1849 18.538L7.30794 12.247L0.951945 8.27202C0.266945 7.84302 -0.091055 7.07902 0.019945 6.27602C0.129945 5.47302 0.680945 4.83502 1.45494 4.60802L16.9109 0.0820158C17.6219 -0.125984 18.3839 0.0710157 18.9079 0.593016C19.4319 1.12002 19.6269 1.89002 19.4149 2.60402L14.8419 18.048C14.6129 18.825 13.9729 19.374 13.1719 19.481C13.0779 19.493 12.9869 19.5 12.8949 19.5Z"
              fill="#767474"
            />
          </g>
        </svg>

        <span>
          <h2>Your Messages</h2>
          <p>Send private photos and messages to a friend</p>
        </span>
        <Button colorScheme="blue" size="sm">
          Send message
        </Button>
      </div>
    </div>
  );
};

export default EmptyChat;
