import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const CommonPostLoader = ({ amount }) => {
      const loadCards = Array(amount).fill(1);
      return loadCards.map((_, i) => (
            <div className="common-post-loader" key={i}>
                  <div className="top-half">
                        <div className="top">
                              <Skeleton circle width={40} height={40} />
                        </div>
                        <div className="middle">
                              <Skeleton count={3} height={5} />
                        </div>
                  </div>
                  <Skeleton height={100} />
            </div>
      ));
};
