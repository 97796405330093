import axios from "axios";

const getSignedUrl = (images)=> {
      console.log(images)
      images.forEach(image => {
            axios.get(`https://api.myreach.me/utility/get-signed-url/${image}`, {
                  headers:{
                        "Content-Type": "multipart/form-data",
                        // "redirect": "follow",
                        "Access-Control-Allow-Origin": "*",
                  }
            })
                  .then((res) =>{
                        console.log(res)
                  })
                  .catch((err)=>{
                        console.log(err);
                  })
      });
}

export default getSignedUrl;

