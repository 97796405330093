
import { post } from "../../../components";
const ShoutOut = () => {
    return (
        <div style={{ position: "relative", padding: "1em" }}>
            <post.ShoutOuts/>
        </div>
    )
}

export default ShoutOut;