//react
import { useEffect, useState } from 'react'

//router
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'

//apollo
import { useLazyQuery } from '@apollo/client/react/hooks'

//apis
import GET_USER_BY_EMAIL from './apis/profile/getUserByEmail'

//state
import { useRecoilState } from 'recoil'
import UserData from './recoil/UserRecoil'
import UserLocation from './recoil/UserLocation'
//framer
import { AnimatePresence } from 'framer-motion'


//major routes
import Home from './views/home/Home'
import PostPage from './views/home/PostPage'
import ComposeReach from './views/ComposeReach'
import Moments from './views/Moments'

//create stories route
import StoriesWrapper from './views/createstory/StoriesWrapper'
import PhotoStory from './views/createstory/PhotoStory'
import AudioStory from './views/createstory/AudioStory'
import TextStory from './views/createstory/TextStory'

//notifications routes
import Notifications from './views/notifications/Notifications'
import AllNotifications from './views/notifications/AllNotifications'
import UnreadNotifications from './views/notifications/UnreadNotifications'


//chat routes
import Chat from './views/chat/Chat'
import EmptyChat from './views/chat/EmptyChat'
import IndividualChat from './views/chat/IndividualChat'


//search routes
import Search from './views/search/Search'
import Explore from './views/search/Explore'
import SearchContainer from './views/search/SearchContainer'


//profile routes
import Profile from './views/profile/Profile'
import Comments from './views/profile/Comments'
import Like from './views/profile/Like'
import Reaches from './views/profile/Reaches'
import ShoutDown from './views/profile/audience/ShoutDown';
import ShoutOut from './views/profile/audience/Shoutout';
import EditProfile from './views/profile/EditProfile'



//profile audience routes
import AudienceContainer from './views/profile/audience/AudienceContainer'
import Reachers from './views/profile/audience/Reachers'
import Reaching from './views/profile/audience/Reaching'
import Star from './views/profile/audience/Star'


//auth routes
import Auth from './views/auth/Auth'
import SignUp from './views/auth/SignUp'
import Activate from './views/auth/Activate'
import ForgotPassword from './views/auth/ForgotPassword'
import ResetPassword from './views/auth/ResetPassword'

//geolocation
import geolocator from 'geolocator'

//error route
import Error from './views/Error'


//borrowed components
import { nav } from './components'


function App() {
  let location = useLocation()
  let navigate = useNavigate()

  const [individualChatRoute, setIndividualChatRoute] = useState('')
  const [individualPostRoute, setIndividualPostRoute] = useState('')
  const [user, setUser] = useRecoilState(UserData)
  const [userLocation, setUserLocation] = useRecoilState(UserLocation)


  const [getUserByIdOrEmail, { data, error }] = useLazyQuery(GET_USER_BY_EMAIL)

  const authRoutes = [
    '/auth',
    '/auth/signup',
    '/auth/activate',
    '/auth/forgot-password',
    '/auth/reset-password'
  ]

  //logout user
  useEffect(() => {

    const authRoutes = [
      '/auth',
      '/auth/signup',
      '/auth/activate',
      '/auth/forgot-password',
      '/auth/reset-password'
    ]

    const getUserLocation = () => {
      geolocator.config({
        language: "en",
        google: {
          version: "3",
          key: process.env.REACT_APP_GOOGLE_API_KEY
        }
      });

      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumWait: 10000,
        maximumAge: 0,
        desiredAccuracy: 30,
        fallbackToIP: true,
        addressLookup: true,
        timezone: true,
      };
      geolocator.locate(options, function (err, location) {
        if (err) return console.log(err);
        setUserLocation(location);
      });
    }

    if (!authRoutes.includes(location.pathname)) {
      if (localStorage.getItem('token') === null) {
        navigate('/auth')
      } else {
        getUserByIdOrEmail({
          variables: { prop: JSON.parse(localStorage.getItem('user')).email }
        })
        if (error) navigate('/auth')
        if (data) {
          setUser(data.getUserByIdOrEmail)
          getUserLocation();
        }
      }
    }
  }, [location, navigate, getUserByIdOrEmail, data, setUser, user, error, setUserLocation, userLocation])


  return (
    <>
      {authRoutes.includes(location.pathname) ||
        location.pathname === '/explore' ||
        location.pathname === '/explore/search' ||
      location.pathname === `/reach/${individualPostRoute}` ? null : (
        <nav.NavBar />
      )}
      {authRoutes.includes(location.pathname) ||
        location.pathname === '/explore' ||
        location.pathname === '/explore/search' ||
        location.pathname === '/chat' ||
      location.pathname === `/chat/r/${individualChatRoute}` ||
      location.pathname === `/reach/${individualPostRoute}` ? null : (
        <nav.MobileNav />
      )}

      <AnimatePresence>
        <Routes>
          {/* major routes */}
          <Route path='/' element={<Home />}>
            <Route
              path='reach/:postid'
              element={<PostPage showMyUrl={setIndividualPostRoute} />}
            />
            <Route path='compose/reach' element={<ComposeReach />} />
          </Route>

          <Route path='/moments' element={<Moments />} />
          <Route path='/notifications' element={<Notifications />}>
            <Route index element={<AllNotifications />} />
            <Route path='unread' element={<UnreadNotifications />} />
          </Route>
          {/* chat routes */}
          <Route path='/chat/' element={<Chat />}>
            <Route index element={<EmptyChat />} />
            <Route
              path='r/:chatid'
              element={<IndividualChat showMyUrl={setIndividualChatRoute} />}
            />
            <Route path='starred' element={<EmptyChat />} />
          </Route>

          {/* search routes */}
          <Route path='/explore' element={<SearchContainer />}>
            <Route index element={<Explore />} />
            <Route path='search' element={<Search />} />
          </Route>

          {/* stories routes */}
          <Route path='/create-story' element={<StoriesWrapper />}>
            <Route index element={<PhotoStory />} />
            <Route path='audio' element={<AudioStory />} />
            <Route path='text' element={<TextStory />} />
          </Route>

          {/* profile routes */}
          <Route path='u/:username' element={<Profile />}>
            <Route index element={<Comments />} />
            <Route path='likes' element={<Like />} />
            <Route path='shoutout' element={<ShoutOut/>} />
            <Route path='shoutdown' element={<ShoutDown/>} />
            <Route path='reaches' element={<Reaches />} />
            <Route path='edit' element={<EditProfile />} />
          </Route>

          <Route path='u/:username/audience' element={<AudienceContainer />}>
            <Route index element={<Reachers />} />
            <Route path='reaching' element={<Reaching />} />
            <Route path='star' element={<Star />} />
          </Route>

          {
            /* auth routes */
          }

          <Route path='/auth' element={<Auth />}>
            <Route path='signup' element={<SignUp />} />
            <Route path='activate' element={<Activate />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='reset-password' element={<ResetPassword />} />
          </Route>


          <Route path='*' element={<Error />} />
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default App

