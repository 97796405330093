import { useState } from "react";

export const PasswordInput = (props) => {
  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const [showPassword, setShowPassword] = useState(false);
  const show = (
    <svg
      onClick={handleClick}
      width="14"
      height="14"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76069 11.6173C7.56869 11.6173 7.37669 11.5443 7.23069 11.3973C6.49269 10.6603 6.08569 9.68025 6.08569 8.63825C6.08569 6.47825 7.84169 4.72125 9.99969 4.72125C11.0377 4.72125 12.0457 5.14025 12.7647 5.87125C13.0547 6.16725 13.0517 6.64125 12.7557 6.93125C12.4607 7.22325 11.9867 7.21825 11.6957 6.92425C11.2567 6.47725 10.6387 6.22125 9.99969 6.22125C8.66869 6.22125 7.58569 7.30525 7.58569 8.63825C7.58569 9.27925 7.83669 9.88325 8.29069 10.3373C8.58369 10.6303 8.58369 11.1043 8.29169 11.3973C8.14469 11.5443 7.95269 11.6173 7.76069 11.6173Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5673 12.4912C10.2123 12.4912 9.89632 12.2372 9.83032 11.8752C9.75632 11.4682 10.0263 11.0772 10.4343 11.0032C11.4143 10.8252 12.1903 10.0472 12.3663 9.06624C12.4403 8.65924 12.8303 8.39124 13.2373 8.46124C13.6453 8.53424 13.9163 8.92424 13.8433 9.33224C13.5563 10.9252 12.2943 12.1892 10.7023 12.4792C10.6573 12.4872 10.6113 12.4912 10.5673 12.4912Z"
        fill="black"
      />
      <mask
        id="mask0_3244_4683"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.624512H16.0862V14.7226H0V0.624512Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3244_4683)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6545 14.7226C4.4925 14.7226 4.3295 14.6696 4.1915 14.5626C2.5005 13.2346 1.0715 11.2876 0.0615 8.93364C-0.0205 8.74364 -0.0205 8.52964 0.0615 8.34064C1.0825 5.97664 2.5205 4.01964 4.2205 2.68264C7.6865 -0.0603611 12.3005 -0.0693612 15.8015 2.70264C16.1265 2.95964 16.1815 3.43164 15.9245 3.75664C15.6665 4.07964 15.1965 4.13664 14.8705 3.87864C11.9045 1.53064 8.0835 1.53864 5.1495 3.86064C3.7135 4.99064 2.4805 6.63664 1.5705 8.63864C2.4715 10.6286 3.6935 12.2646 5.1185 13.3826C5.4445 13.6386 5.5005 14.1106 5.2445 14.4356C5.0965 14.6236 4.8765 14.7226 4.6545 14.7226Z"
          fill="black"
        />
      </g>
      <mask
        id="mask1_3244_4683"
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="14"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.71753 4.74121H20.0001V16.6894H6.71753V4.74121Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_3244_4683)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 16.6894C9.06316 16.6894 8.13116 16.5374 7.23116 16.2384C6.83816 16.1074 6.62516 15.6824 6.75616 15.2894C6.88716 14.8954 7.31016 14.6864 7.70516 14.8144C8.45216 15.0634 9.22416 15.1894 10.0002 15.1894C13.4282 15.1894 16.5612 12.7474 18.4302 8.63642C17.9742 7.63742 17.4432 6.73242 16.8492 5.94242C16.6002 5.61142 16.6662 5.14042 16.9972 4.89142C17.3272 4.64242 17.7982 4.71042 18.0472 5.04042C18.7712 6.00142 19.4072 7.11242 19.9382 8.33842C20.0212 8.52842 20.0212 8.74442 19.9382 8.93342C17.8422 13.7904 14.1272 16.6894 10.0002 16.6894Z"
          fill="black"
        />
      </g>
      <mask
        id="mask2_3244_4683"
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.36353 0.000427246H18.637V17.2734H1.36353V0.000427246Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_3244_4683)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11328 17.2734C1.92128 17.2734 1.72928 17.2004 1.58328 17.0534C1.29028 16.7604 1.29028 16.2864 1.58328 15.9934L17.3573 0.219445C17.6503 -0.0735552 18.1243 -0.0735552 18.4173 0.219445C18.7103 0.512445 18.7103 0.987445 18.4173 1.28044L2.64328 17.0534C2.49728 17.2004 2.30528 17.2734 2.11328 17.2734Z"
          fill="black"
        />
      </g>
    </svg>
  );

  const hide = (
    <svg
      onClick={handleClick}
      width="14"
      height="14"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99962 5.6413C8.66962 5.6413 7.58862 6.7233 7.58862 8.0533C7.58862 9.3823 8.66962 10.4633 9.99962 10.4633C11.3296 10.4633 12.4116 9.3823 12.4116 8.0533C12.4116 6.7233 11.3296 5.6413 9.99962 5.6413ZM9.99962 11.9633C7.84262 11.9633 6.08862 10.2093 6.08862 8.0533C6.08862 5.8963 7.84262 4.1413 9.99962 4.1413C12.1566 4.1413 13.9116 5.8963 13.9116 8.0533C13.9116 10.2093 12.1566 11.9633 9.99962 11.9633Z"
        fill="black"
      />
      <mask
        id="mask0_3244_4598"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000244141 0.000183105H20.0001V16.1052H0.000244141V0.000183105Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3244_4598)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.56999 8.05251C3.42999 12.1615 6.56299 14.6045 9.99999 14.6055C13.437 14.6045 16.57 12.1615 18.43 8.05251C16.57 3.94451 13.437 1.50151 9.99999 1.50051C6.56399 1.50151 3.42999 3.94451 1.56999 8.05251ZM10.002 16.1055H9.99799H9.99699C5.86099 16.1025 2.14699 13.2035 0.0609941 8.34851C-0.0200059 8.15951 -0.0200059 7.94551 0.0609941 7.75651C2.14699 2.90251 5.86199 0.00350586 9.99699 0.000505859C9.99899 -0.000494141 9.99899 -0.000494141 9.99999 0.000505859C10.002 -0.000494141 10.002 -0.000494141 10.003 0.000505859C14.139 0.00350586 17.853 2.90251 19.939 7.75651C20.021 7.94551 20.021 8.15951 19.939 8.34851C17.854 13.2035 14.139 16.1025 10.003 16.1055H10.002Z"
          fill="black"
        />
      </g>
    </svg>
  );

  const showType = "text";
  const hideType = "password";

  return (
    <div className="password-input">
      <input
        type={showPassword ? showType : hideType}
        placeholder={props.placeholder}
        id={props.id}
        name={props.name}
        onChange={props.change}
        value={props.value}
        onBlur={props.blur}
      />
      {showPassword ? show : hide}
    </div>
  );
};

