//react
import { useEffect } from "react";
//router
import { NavLink, useNavigate } from "react-router-dom";
//apollo
import { useLazyQuery } from "@apollo/client";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//api
import LOGIN_USER from "../apis/auth/AuthLogin";
//components
import { Button } from "@chakra-ui/react"
import { interact, alert } from "../components";

const Login = () => {
  let navigate = useNavigate();

  const [login, { loading, error, data }] = useLazyQuery(LOGIN_USER);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data) {
        localStorage.setItem("token", data.login.token);
        localStorage.setItem("user", JSON.stringify(data.login));
        navigate("/", {replace:true});
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [data, navigate]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const { email, password } = values;
      login({ variables: { email: email, password: password } });
    },
  });

  return (
    <form className="login-form" method="POST" onSubmit={formik.handleSubmit}>
      {error ? <alert.ErrorToast errorMessage={error.message} /> : null}
      <interact.Input
        type="email"
        placeholder="Email"
        id="email"
        name="email"
        change={formik.handleChange}
        blur={formik.handleBlur}
        value={formik.values.email}
      />
      {formik.touched.email && formik.errors.email ? (
        <div className="formik-error">Invalid email</div>
      ) : null}
      <interact.PasswordInput
        placeholder="Password"
        id="password"
        name="password"
        change={formik.handleChange}
        blur={formik.handleBlur}
        value={formik.values.password}
      />
      {formik.touched.password && formik.errors.password ? (
        <div className="formik-error">password required</div>
      ) : null}
      <NavLink to="/auth/forgot-password" className="forgot-password">
        Forgot password?
      </NavLink>
      <Button
      className="button"
        isLoading={loading}
        onClick={formik.handleSubmit}
        type="submit"
      >Done</Button>

      {/* <div className="or">
        <hr />
        <span>or</span>
      </div> */}

      {/* <button className="google">
        <svg width="20" height="20" viewBox="0 0 28 26" fill="none">
          <g clipPath="url(#clip0_3897_9746)">
            <path
              d="M27.5409 13.2306C27.5409 12.1664 27.4472 11.3898 27.2443 10.5845H14.0522V15.3877H21.7956C21.6396 16.5814 20.7965 18.379 18.9231 19.587L18.8968 19.7478L23.0679 22.7243L23.3569 22.7509C26.0109 20.493 27.5409 17.171 27.5409 13.2306Z"
              fill="#4285F4"
            />
            <path
              d="M14.0522 25.8861C17.8458 25.8861 21.0306 24.7356 23.3569 22.751L18.9231 19.5871C17.7366 20.3493 16.1441 20.8814 14.0522 20.8814C10.3365 20.8814 7.18295 18.6236 6.05879 15.5029L5.89401 15.5158L1.55684 18.6078L1.50012 18.753C3.81067 22.981 8.55671 25.8861 14.0522 25.8861Z"
              fill="#34A853"
            />
            <path
              d="M6.05853 15.5029C5.76191 14.6976 5.59025 13.8346 5.59025 12.9431C5.59025 12.0514 5.76191 11.1885 6.04293 10.3832L6.03507 10.2117L1.64355 7.07007L1.49987 7.13302C0.547584 8.88754 0.00115967 10.8578 0.00115967 12.9431C0.00115967 15.0283 0.547584 16.9985 1.49987 18.753L6.05853 15.5029Z"
              fill="#FBBC05"
            />
            <path
              d="M14.0522 5.0046C16.6905 5.0046 18.4703 6.05442 19.4851 6.93173L23.4505 3.36519C21.0151 1.27993 17.8458 0 14.0522 0C8.55671 0 3.81067 2.90497 1.50012 7.13299L6.04318 10.3832C7.18295 7.26247 10.3365 5.0046 14.0522 5.0046Z"
              fill="#EB4335"
            />
          </g>
          <defs>
            <clipPath id="clip0_3897_9746">
              <rect width="27.5554" height="25.9753" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p>Continue with Google</p>
      </button> */}

      <p className="no-account">
        Do not have an account? <NavLink to="/auth/signup">Sign up</NavLink>
      </p>
    </form>
  );
};

export default Login;
