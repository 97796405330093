import { gql } from "@apollo/client/core";

const SUGGEST_USER = gql`
 query suggestUser{
  suggestUser{
    authId,
    reaching{
      reacherId,
      reachingId
    },
    username,
    profilePicture
  }
}
`;

export default SUGGEST_USER;
