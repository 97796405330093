import { gql } from "@apollo/client";

const GET_USER_BY_EMAIL = gql`
  query getUser($prop: String!) {
    getUserByIdOrEmail(prop: $prop) {
      authId
      bio
      coverPicture
      dateOfBirth
      email
      firstName
      gender
      lastName
      location
      nReachers
      nReaching
      nStaring
      phone
      profilePicture
      profileSlug
      showContact
      showLocation
      username
      verified
    }
  }
`;

export default GET_USER_BY_EMAIL;