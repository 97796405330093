import { gql } from "@apollo/client/core";

const UPDATE_USER = gql`
  mutation updateUser($userData: UpdateProfileInput!) {
    updateUser(userData: $userData) {
      authId
    }
  }
`;

export default UPDATE_USER;