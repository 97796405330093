import { gql } from '@apollo/client'

const GET_THREAD_MESSAGES = gql`
  query getThreadMessages($id: String!, $fromMessageId: String!) {
    getThreadMessages(id: $id, fromMessageId: $fromMessageId) {
      senderId
      receiverId
      value
      sentAt
    }
  }
`

export default GET_THREAD_MESSAGES
