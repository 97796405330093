import { gql} from "@apollo/client";

  const REGISTER_USER = gql`
    mutation createAccount($data: CreateAccountInput!) {
      createAccount(data: $data) {
        created_at
        email
        firstName
        id
        isActive
        lastLogin
        lastName
        phone
        token
        updated_at
      }
    }
  `

export default REGISTER_USER;
