import { gql } from "@apollo/client/core";

const ACTIVATE_ACCOUNT = gql`
  query activateAccount($email: String!, $pin: Int!) {
    activateAccount(email: $email, pin: $pin) {
      created_at
      email
      firstName
      id
      isActive
      lastLogin
      lastName
      phone
      token
      updated_at
    }
  }
`

export default ACTIVATE_ACCOUNT;
