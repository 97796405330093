import { util } from "../../components";

const UnreadNotifications = () => {
  return (
    <div className="unread-notifications-container">
      <util.ReachUser
        username="jake"
        fullName="Nelson Michael"
        bio="Life na beans, shebi i don tell you before naaa"
      />
    </div>
  );
};

export default UnreadNotifications;
