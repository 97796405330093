import ActivateAccount from "../../containers/ActivateAccount";
import { interact } from "../../components";

const Activate = () => {
  return (
    <div className="activate-acount-container wrapper">
      <div className="form-container">
        <interact.CloseButton where="auth" />
        <ActivateAccount />
      </div>
    </div>
  );
};

export default Activate;
