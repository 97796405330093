import { gql } from "@apollo/client";

const GET_SEARCH_PROFILE = gql`
query searchProfile($limit: Int!, $name: String!, $pageNumber: Int!){
  searchProfile(limit: $limit, name:$name, pageNumber:$pageNumber){
    authId,
    firstName,
    lastName,
    profilePicture,
    username,
    verified
  }
}`;
export default GET_SEARCH_PROFILE;