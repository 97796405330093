//apis
import GET_USER_BY_USERNAME from "../../apis/profile/getUserByUsername";
import GET_REACH_RELATIONSHIP from "../../apis/profile/getReachRelationship";
import REACH_USERS from "../../apis/subtleInteractions/setReachUsers";
//state
import { useState } from "react";
import { useRecoilState } from "recoil";
import UserData from "../../recoil/UserRecoil";
import Username from "../../recoil/UsernameRecoil";
//router
import {
  Outlet,
  useParams,
  useNavigate,
  NavLink,
  Link,
} from "react-router-dom";
//react
import { useEffect } from "react";
//apollo
import { useLazyQuery, useMutation } from "@apollo/client/react/hooks";
//components
import { nav, util, interact, modals } from "../../components";
import { Button, Avatar } from "@chakra-ui/react";
//assets
import nocover from "../../assets/nocover.png";

const Profile = (props) => {
  let params = useParams();
  let navigate = useNavigate();
  const [userProfile, setUserProfile] = useState("");
  const {
    firstName,
    lastName,
    nReachers,
    nReaching,
    nStaring,
    bio,
    profilePicture,
    // coverPicture,
    username,
    authId,
  } = userProfile;

  //global currently logged in user
  const [user] = useRecoilState(UserData);
  const [navUsername] = useRecoilState(Username);
  //apis

  const [getUserByUsername, { data: currentUser }] =
    useLazyQuery(GET_USER_BY_USERNAME);

  const [getReachRelationship, { data: reachRelationship }] = useLazyQuery(
    GET_REACH_RELATIONSHIP
  );
  const [reachUser, { loading }] = useMutation(REACH_USERS);

  const reRouteToEditProfile = () => {
    navigate(`/u/${username}/edit`);
  };

  const reachUserHandler = () => {
    reachUser({
      variables: {
        userIdToReach: authId,
      },
    });
  };

  useEffect(() => {
    getUserByUsername({ variables: { username: params.username } });
    if (currentUser) {
      if (currentUser.getUserByUsername.length > 0) {
        //check to see if current user is reaching or reacher
        getReachRelationship({ variables: { userIdToReach: authId } });
        setUserProfile(currentUser.getUserByUsername[0]);
      }
    }
  }, [
    authId,
    currentUser,
    getReachRelationship,
    getUserByUsername,
    params.username
  ]);

  //if user doesn't exist display fallback UI
  if (currentUser) {
    if (currentUser.getUserByUsername.length === 0) {
      return <util.NotFound notFoundUser={params.username} />;
    }
  }

  return (
    <>
      <div className="profile-page">
        <div className="profile-container">
          <div className="profile__main">
            <div className="user-profile-container">
              <div className="profile-photos">
                <div className="cover-photo">
                  <img
                    src={nocover}
                    alt="user cover"
                  />
                </div>
                <Avatar className="image-formatter-container" size="xl" src={userProfile ? profilePicture : null} />
              </div>

              <div className="profile-desc">
                <p className="username">
                  {userProfile ? `${firstName} ${lastName}` : ""}
                </p>
                <div className="audience">
                  <Link to={`/u/${username}/audience/`}>
                    <span>
                      <p className="val">{nReachers}</p>
                      <p>Reachers</p>
                    </span>
                  </Link>
                  <Link to={`/u/${username}/audience/reaching`}>
                    <span>
                      <p className="val">{nReaching}</p>
                      <p>Reaching</p>
                    </span>
                  </Link>
                  <Link to={`/u/${username}/audience/star`}>
                    <span>
                      <p className="val">{nStaring}</p>
                      <p>Star</p>
                    </span>
                  </Link>
                </div>

                <p className="bio">{bio ? bio : ""}</p>

                {authId === user.authId ? (
                  <button
                    className="edit-profile-btn"
                    onClick={reRouteToEditProfile}
                  >
                    Edit Profile
                  </button>
                ) : reachRelationship ? (
                  reachRelationship.getReachRelationship ? (
                    <interact.ReachingButton />
                  ) : (
                    <Button
                      className="edit-profile-btn"
                      variant="outline"
                      size="sm"
                      onClick={reachUserHandler}
                      isLoading={loading ? true : false}
                    >
                      Reach
                    </Button>
                  )
                ) : null}
              </div>
            </div>

            <div className="profile-container__nav">
              <div className="profile-container__nav-items">
                <NavLink to={`/u/${username}/`}>
                  <p>Comments</p>
                </NavLink>
                <NavLink to={`/u/${username}/reaches`}>
                  <p>Reaches</p>
                </NavLink>
                <NavLink to={`/u/${username}/likes`}>
                  <p>Likes</p>
                </NavLink>
                <NavLink to={`/u/${username}/shoutout`}>
                  <p>Shoutouts</p>
                </NavLink>
                <NavLink to={`/u/${username}/shoutdown`}>
                  <p>Shoutdowns</p>
                </NavLink>
              </div>
              <Outlet />
            </div>
          </div>

          <aside>
            <modals.ReachSuggestionContainer />
          </aside>
        </div>
      </div>
      <nav.BottomNav link={navUsername} />
    </>
  );
};

export default Profile;

// loading: isUserLoading, error: isUserError
// , loading: isReachRelationshipLoading, error: isReachRelationshipError
