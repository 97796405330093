//router
import { Outlet, NavLink, Link } from "react-router-dom";

//components
import { nav, util } from "../../components";
const Notifications = (props) => {
  return (
    <>
      <div className="notifications-page">
        <div className="notifications-container">
          <div className="notifications-main">
            <nav>
              <div className="navigate">
                <Link to={``}>
                  <p> Notifications</p>
                </Link>
              </div>

              <ul>
                <NavLink to="/notifications/">
                  <li>All</li>
                </NavLink>
                <NavLink to="/notifications/unread">
                  <li>Unread</li>
                </NavLink>
              </ul>
            </nav>
            {/* {userLoading ? (
              <div className="load-spinner">
                <img src={spinner} alt="spinner" />
              </div>
            ) : (
              //put outlet back here, when data is ready
            )} */}
            <Outlet />
          </div>
          <aside>
            <div className="reach-suggestion-container">
              <header>
                <h2>Reach Suggested for</h2>
              </header>
              <div className="wrapper">
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                  name="Rooney Brown"
                  info="New to reach me"
                />
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                  name="Ragna Lothbrok"
                  info="Reached by Stanchiqa"
                />
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1503235930437-8c6293ba41f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  name="Bjorn Lothbrok"
                  info="New to reach me"
                />
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1533636721434-0e2d61030955?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  name="Karl Djurn"
                  info="Reaches you"
                />
              </div>
            </div>
          </aside>
        </div>
      </div>
      <nav.BottomNav />
    </>
  );
};

export default Notifications;
