export const ImageUploader = ({ width, height, marginBottom, uploadImage, changeImage }) => {
  return (
    <span className="image-uploader" style={{ width: width, height: height, marginBottom: marginBottom }}>
      <input type="file" name="profile-photo" accept="image/*" onChange={(e)=>{
        uploadImage(e.target.files[0], changeImage)
      }} />
      <svg
        width="14"
        height="14"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.19406 1.83152C5.59601 1.10099 6.03328 0.612646 6.68213 0.35439C7.87114 -0.11839 12.1295 -0.11839 13.3176 0.354485C13.9661 0.612601 14.4035 1.10086 14.8058 1.83124L15.258 2.69839C15.2684 2.71557 15.2779 2.73068 15.2864 2.74368L15.3248 2.79619C15.5308 3.02069 15.8757 3.15632 16.1032 3.15632C18.2553 3.15632 20.0002 4.90091 20.0002 7.05232V12.8473C20.0002 15.5915 17.7745 17.8173 15.0302 17.8173H4.97024C2.22535 17.8173 0.000244141 15.5918 0.000244141 12.8473V7.05232C0.000244141 4.90091 1.74522 3.15632 3.89724 3.15632C4.12402 3.15632 4.46932 3.02049 4.67568 2.79619C4.67981 2.7917 4.68519 2.78501 4.69169 2.77615L4.74312 2.69727L5.19406 1.83152ZM13.4919 2.5549C13.2419 2.10106 13.016 1.84891 12.7629 1.74815L12.6436 1.70848C11.6144 1.4176 8.03465 1.43082 7.2366 1.74815C6.98358 1.84886 6.75793 2.10087 6.50826 2.55462L6.1234 3.29872L6.02868 3.47015C5.9496 3.60168 5.87051 3.7127 5.78019 3.81111C5.27373 4.36161 4.52449 4.65632 3.89724 4.65632L3.73315 4.66185C2.48599 4.74618 1.50024 5.78453 1.50024 7.05232V12.8473C1.50024 14.7635 3.05383 16.3173 4.97024 16.3173H15.0302C16.946 16.3173 18.5002 14.7631 18.5002 12.8473V7.05232C18.5002 5.72941 17.4269 4.65632 16.1032 4.65632L15.9291 4.64904C15.3406 4.60061 14.6795 4.31148 14.2197 3.81044C14.1306 3.71334 14.0519 3.6031 13.9733 3.47269C13.962 3.45398 13.948 3.42935 13.9319 3.4002L13.8158 3.18246L13.4919 2.5549Z"
          fill="#ffffff"
        />
        <path
          d="M15.5046 5.5C16.0569 5.5 16.5046 5.94772 16.5046 6.5C16.5046 7.01284 16.1186 7.43551 15.6212 7.49327L15.5046 7.5C14.9433 7.5 14.4956 7.05228 14.4956 6.5C14.4956 5.98716 14.8816 5.56449 15.379 5.50673L15.5046 5.5Z"
          fill="#ffffff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.07104 10.128C6.07104 7.95782 7.82983 6.19904 10 6.19904C12.1703 6.19904 13.929 7.95782 13.929 10.128C13.929 12.2982 12.1703 14.057 10 14.057C7.82983 14.057 6.07104 12.2982 6.07104 10.128ZM12.429 10.128C12.429 8.78625 11.3418 7.69904 10 7.69904C8.65826 7.69904 7.57104 8.78625 7.57104 10.128C7.57104 11.4698 8.65826 12.557 10 12.557C11.3418 12.557 12.429 11.4698 12.429 10.128Z"
          fill="#ffffff"
        />
      </svg>
    </span>
  );
};
