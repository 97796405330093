import { animation } from ".."
import { Link } from "react-router-dom"
export const StoryModal = ({ storyModalRef }) => {
      return (
            <animation.SlideIn>
                  <div className="story-modal" ref={storyModalRef}>
                        <Link to="/create-story/">
                              <div className="span">
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g id="Iconly/Light/Image">
                                                <g id="Image">
                                                      <path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M21.21 7.89924V16.0502C21.21 19.0702 19.32 21.2002 16.3 21.2002H7.65C4.63 21.2002 2.75 19.0702 2.75 16.0502V7.89924C2.75 4.87924 4.64 2.75024 7.65 2.75024H16.3C19.32 2.75024 21.21 4.87924 21.21 7.89924Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path id="Stroke 3" d="M5.28125 16.4311L6.80925 14.8181C7.34025 14.2551 8.22525 14.2281 8.78925 14.7581C8.80625 14.7751 9.72625 15.7101 9.72625 15.7101C10.2813 16.2751 11.1883 16.2841 11.7533 15.7301C11.7903 15.6941 14.0872 12.9081 14.0872 12.9081C14.6792 12.1891 15.7422 12.0861 16.4622 12.6791C16.5102 12.7191 18.6803 14.9461 18.6803 14.9461" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                      <path id="Stroke 5" fillRule="evenodd" clipRule="evenodd" d="M10.3127 9.13315C10.3127 10.1022 9.52769 10.8872 8.55869 10.8872C7.58969 10.8872 6.80469 10.1022 6.80469 9.13315C6.80469 8.16415 7.58969 7.37915 8.55869 7.37915C9.52769 7.38015 10.3127 8.16415 10.3127 9.13315Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                          </g>
                                    </svg>

                                    <p>Photo story</p>
                              </div>
                        </Link>

                        <Link to="/create-story/text/">
                        <div className="span">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Light/Edit">
                                          <g id="Edit">
                                                <path id="Stroke 1" d="M13.7473 20.4428H20.9999" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path id="Stroke 3" fillRule="evenodd" clipRule="evenodd" d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path id="Stroke 5" d="M11.0208 6.00098L16.473 10.1881" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                          </g>
                                    </g>
                              </svg>

                              <p>Text story</p>
                        </div>
                        </Link>

                        <Link to="/create-story/audio/">
                        <div className="span">
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Iconly/Light/Voice">
                                          <g id="Voice">
                                                <path id="Stroke 1" d="M11.9997 22.0001V18.8391" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path id="Stroke 3" fillRule="evenodd" clipRule="evenodd" d="M11.9998 14.8481V14.8481C9.75662 14.8481 7.93774 13.0218 7.93774 10.7682V6.08095C7.93774 3.82732 9.75662 2 11.9998 2C14.2441 2 16.0619 3.82732 16.0619 6.08095V10.7682C16.0619 13.0218 14.2441 14.8481 11.9998 14.8481Z" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path id="Stroke 5" d="M20 10.8005C20 15.2394 16.4188 18.8382 11.9995 18.8382C7.58117 18.8382 4 15.2394 4 10.8005" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                          </g>
                                    </g>
                              </svg>

                              <p>Audio story</p>
                        </div>
                        </Link>
                  </div>
            </animation.SlideIn>
      )
}