//components
import { Avatar } from "@chakra-ui/react";


export const MyStory = ({ action, value }) => {
      return (
            <div className="my-story" onClick={() => {
                  action(!value);
            }}>
                  <span className="story-ring">
                        <Avatar
                              size="md"
                              src=""
                        />
                        <span className="story-icon">
                              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.55249 0.864014V10.2447" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M0.862549 5.55396L10.2432 5.55395" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                              </svg>
                        </span>

                  </span>

                  <p>My Story</p>
            </div>
      )
}