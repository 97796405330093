//router
import { Link } from "react-router-dom";
//components
import { Avatar } from "@chakra-ui/react"
import { interact } from ".."

export const ReachSuggestion = ({ pureName, name, info, photo, index }) => {
    return (
        <div className="reach-suggestion" key={index}>
            <Link to={`/u/${pureName}`} >
                <div className="desc">
                    <Avatar size="sm" src={photo} />
                    <div className="details">
                        <h2 className="name">{name}</h2>
                        <p className="info">{info}</p>
                    </div>
                </div>
            </Link>
            <interact.ReachButton />
        </div>
    )
}