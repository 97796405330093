
import { useNavigate } from "react-router-dom";
import { Avatar } from "@chakra-ui/react";

export const SearchedUser = ({ userName, lastName, firstName, photo, verified, setCloseModal }) => {
  let navigate = useNavigate();
  return (
    <div className="searched-user" onClick={()=>{
        navigate(`/u/${userName}`);
      setCloseModal(false);
    }}>
      <Avatar className="avater" size="sm" src={photo} />
      <div className="user-details">
        <h2>{userName}</h2>
        <p>{`${firstName} ${lastName}`}</p>
      </div>
    </div>
  );
};

