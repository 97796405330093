import React from 'react'
import './styles/main.css'
import App from './App'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ChakraProvider } from '@chakra-ui/react'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  // split
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
// import { getMainDefinition } from '@apollo/client/utilities'
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
// import { createClient } from 'graphql-ws'

import { SkeletonTheme } from 'react-loading-skeleton'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL
})

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: process.env.REACT_APP_WS_SUB_URL
//   })
// )

const authLink = setContext((_, { headers }) => {
const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     )
//   },
//   wsLink,
//   authLink.concat(httpLink)
// )

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <RecoilRoot>
        <ChakraProvider resetCSS={false}>
          <SkeletonTheme baseColor='#d9d9d9'>
            <App />
          </SkeletonTheme>
        </ChakraProvider>
      </RecoilRoot>
    </ApolloProvider>
  </BrowserRouter>
)
