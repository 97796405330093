import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SkeletonLoader = ({ amount }) => {
  const loadCards = Array(amount).fill(1);
  return loadCards.map((_, i) => (
    <div className="skeleton-loader" key={i}>
      <div className="top-half">
        <div className="top">
          <Skeleton circle width={50} height={50} />
        </div>
        <div className="middle">
          <Skeleton count={3} height={5} />
        </div>
      </div>

      <Skeleton height={150} />

      <div className="bottom-half">
        <div className="left">
          <Skeleton
            className="int"
            width={20}
            height={20}
            count={3}
            inline={true}
          />
        </div>
        <div className="right">
          <Skeleton
            className="int"
            circle
            width={20}
            height={20}
            count={2}
            inline={true}
          />
        </div>
      </div>
    </div>
  ));
};
