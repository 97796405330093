export const Message = ({ content, position }) => {
      return (
            <div className="span">
                  <div className={position ? 'message' : 'message position-right'}>
                        <span>
                              <p >{content}</p>
                              <p>4:30 PM</p>
                        </span>

                  </div>
            </div>

      )
};