//api
import { useMutation } from "@apollo/client";
import LIKE_COMMENT from "../../apis/posts/comments/setLikeComment";
//components
import { Avatar } from "@chakra-ui/react";

export const CommentItem = ({ feed, refetchComments, postId }) => {
  // const navigate = useNavigate();

  const [likeCommentOnPost, { data: dataLikeComment }] = useMutation(LIKE_COMMENT);


  if(dataLikeComment) {
    		refetchComments({
			page_limit: 100,
			page_number: 1,
			postId: `${postId}`,
		});
  }

  return feed && feed.getAllCommentsOnPost.length !== 0
    ? feed.getAllCommentsOnPost.map((post, index) => (
        <div className="comments-over-wrap" key={post.postId + index}>
          <div className="comment-container">
            <header>
            <Avatar size="sm" src={post.profile.profilePicture} />
              <span>
                <h2>{`${post.profile.firstName} ${post.profile.lastName}`}</h2>
              <p>Lagos,Nigeria</p>
                <p>1w.</p>
              </span>
            </header>

            <div className="post-content">
              <div className="post-content__text">
                <p>{post.content}</p>
                {/* <span>..Show more</span> */}
              </div>
              {/* <div className="post-content__image">
             <div>
               <img
                 src="https://images.unsplash.com/photo-1653661242725-c863e8020586?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                 alt="post"
               />
             </div>
             <div>
               <img
                 src="https://images.unsplash.com/photo-1612699001349-617d44e98faf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                 alt="post"
               />
             </div>
             <div>
               <img
                 src="https://images.unsplash.com/photo-1610902229766-f3351b6efd11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
                 alt="post"
               />
             </div>
             <div>
               <img
                 src="https://images.unsplash.com/photo-1610902254192-75576ebe871a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
                 alt="post"
               />
             </div>
           </div> */}
            </div>

            <div className="post-interactions">
              <div className="actions">
                <span onClick={()=>{
                  likeCommentOnPost({
                    variables: {
                      commentId: post.commentId,
                      postId: post.postId,
                    }
                  })
                }}>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 26 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.3631 13.1129L24.0423 13.5244L24.1963 14.023C24.2825 14.3017 24.328 14.5987 24.3337 14.9063C24.3319 15.5686 24.1072 16.2083 23.6968 16.7348L23.376 17.1463L23.5301 17.6449C23.6172 17.9267 23.661 18.2184 23.661 18.5169C23.661 19.1821 23.4362 19.8249 23.0241 20.3535L22.7033 20.7651L22.8573 21.2636C22.9444 21.5455 22.9882 21.8371 22.9882 22.1357C22.9882 23.3453 22.2471 24.4561 21.061 24.9472L21.061 24.9472L21.0554 24.9495C20.9145 25.0088 20.7547 25.0415 20.5822 25.0415H6.2325V12.7121L8.99583 3.00006L8.99602 2.99936C9.31755 1.86621 10.4064 1.04153 11.6675 1.04153L11.6711 1.04152C12.0124 1.04029 12.3496 1.10706 12.6616 1.23691C12.9736 1.36675 13.2527 1.55645 13.4826 1.79272L13.4841 1.79427C13.9556 2.27669 14.1909 2.89655 14.1592 3.55127C14.1592 3.55145 14.1592 3.55163 14.1592 3.55181L13.9643 7.42753L13.9114 8.47775H14.963H22.7565H22.7574C22.9768 8.47756 23.1906 8.53437 23.3764 8.64014C24.4036 9.2251 25 10.2486 25 11.2763C25 11.9415 24.7752 12.5843 24.3631 13.1129ZM1.00082 25.0286C1.00051 25.0283 1.00024 25.028 1 25.0278V13.552C1.00024 13.5518 1.00051 13.5515 1.00082 13.5512C1.00363 13.5485 1.00805 13.5453 1.01411 13.5428C1.0196 13.5405 1.02777 13.5383 1.04 13.5383H2.1525V25.0415H1.04C1.02777 25.0415 1.0196 25.0393 1.01411 25.037C1.00805 25.0345 1.00362 25.0313 1.00082 25.0286ZM1 13.5478C0.999999 13.5478 1 13.5479 1 13.5479L1 13.5478Z"
                      stroke="#252525"
                      strokeWidth="2"
                    />
                  </svg>
                  <p>{post.nLikes}</p>
                </span>

                {/* <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.23077 3.65523V3.55567C3.23077 2.61265 3.61978 1.70825 4.31222 1.04143C5.00466 0.374614 5.94382 0 6.92308 0H18C19.5913 0 21.1174 0.608748 22.2426 1.69233C23.3679 2.77591 24 4.24555 24 5.77797V12.4449C24 13.3879 23.611 14.2923 22.9185 14.9591C22.2261 15.6259 21.287 16.0005 20.3077 16.0005V16.445C20.3077 17.5059 19.8701 18.5233 19.0911 19.2735C18.3121 20.0237 17.2555 20.4451 16.1538 20.4451H10.9477L6.88338 23.6897C5.98154 24.4115 4.61538 23.7937 4.61538 22.6647V20.4451H3.69231C2.71305 20.4451 1.77389 20.0705 1.08145 19.4037C0.38901 18.7369 0 17.8325 0 16.8894V7.5558C0.000225249 6.6494 0.319968 5.76991 0.906889 5.06131C1.49381 4.35271 2.31314 3.85697 3.23077 3.65523ZM5.07692 3.55567H16.1538C17.2555 3.55567 18.3121 3.97711 19.0911 4.72728C19.8701 5.47745 20.3077 6.4949 20.3077 7.5558V14.2227C20.7973 14.2227 21.2669 14.0354 21.6131 13.702C21.9593 13.3686 22.1538 12.9164 22.1538 12.4449V5.77797C22.1538 4.71707 21.7162 3.69962 20.9372 2.94945C20.1582 2.19928 19.1017 1.77784 18 1.77784H6.92308C6.43345 1.77784 5.96387 1.96514 5.61765 2.29855C5.27143 2.63196 5.07692 3.08416 5.07692 3.55567ZM4.15385 5.33351C3.54181 5.33351 2.95484 5.56764 2.52206 5.9844C2.08929 6.40116 1.84615 6.96641 1.84615 7.5558V16.8894C1.84615 17.361 2.04066 17.8132 2.38688 18.1466C2.7331 18.48 3.20268 18.6673 3.69231 18.6673H5.53846C5.78328 18.6673 6.01806 18.7609 6.19118 18.9276C6.36429 19.0943 6.46154 19.3204 6.46154 19.5562V21.718L10.0265 18.8717C10.192 18.7396 10.4003 18.6673 10.6154 18.6673H16.1538C16.4569 18.6673 16.757 18.6098 17.037 18.4981C17.3169 18.3864 17.5713 18.2227 17.7856 18.0164C17.9999 17.81 18.1699 17.565 18.2859 17.2954C18.4018 17.0258 18.4615 16.7368 18.4615 16.445V7.5558C18.4615 6.96641 18.2184 6.40116 17.7856 5.9844C17.3529 5.56764 16.7659 5.33351 16.1538 5.33351H4.15385Z"
                      fill="#252525"
                    />
                  </svg>
                  <p>{post.nComments}</p>
                </span> */}

                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 25 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.5849 3.18993L21.5872 3.19216C22.3523 3.95654 22.7778 4.98381 22.7778 6.06V6.24631C22.7288 6.09673 22.6672 5.95012 22.5931 5.80802C22.5951 5.85964 22.5961 5.91153 22.5961 5.96364V8.50457C22.6687 8.36409 22.7293 8.21893 22.7778 8.07061V15.54C22.7778 17.3932 21.5277 18.9612 19.8203 19.4456C19.4344 19.5695 19.0231 19.6364 18.5961 19.6364H6.95972C6.53603 19.6364 6.12772 19.5705 5.74448 19.4484C4.03237 18.9674 2.77783 17.3973 2.77783 15.54V7.85128C2.81951 8.03825 2.88003 8.22233 2.95972 8.40063V6.02959C2.88051 6.20824 2.81976 6.39365 2.77783 6.58278V6.06C2.77783 4.1891 4.04135 2.61246 5.77301 2.14261C6.14796 2.02629 6.54652 1.96364 6.95972 1.96364H18.5961C19.0264 1.96364 19.4409 2.0316 19.8294 2.15737C20.4884 2.34567 21.0943 2.69828 21.5849 3.18993ZM18.7046 0C20.3138 0 21.8618 0.636 23.0006 1.7772C24.1406 2.916 24.7778 4.452 24.7778 6.06V15.54C24.7778 18.888 22.0538 21.6 18.7046 21.6H6.84983C3.50063 21.6 0.777832 18.888 0.777832 15.54V6.06C0.777832 2.712 3.48863 0 6.84983 0H18.7046Z"
                      fill="#252525"
                    />
                    <path
                      d="M5.90503 6.87273L12.867 11.7818L18.6687 6.87273"
                      stroke="#252525"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>


            </div>
          </div>
        </div>
      ))
    : null;
};
