export const ImagePostUploader = ({  getImageFilePath, setPreviewImages, setImagesName,  children }) => {
      return (
            <span className="image-post-uploader" >
                  <input type="file" name="profile-photo" accept="image/*" onChange={(e) => {
                        getImageFilePath(e.target.files[0], setPreviewImages, setImagesName)
                  }} />
                  { children }
            </span>
      );
};
