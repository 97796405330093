import { Outlet, NavLink } from "react-router-dom";

const StoriesWrapper = () => {
      return (
            <div className="create-stories-wrapper">
                  <div className="create-stories">
                        <div className="stories-ctrl-panel">
                              <div className="header">
                                    <h1>Your Story</h1>
                                    <ul className="stories-nav">
                                          <li>
                                                <NavLink to="/create-story/">Photo</NavLink>
                                          </li>
                                          <li>
                                                <NavLink to="/create-story/text/">Text</NavLink>
                                          </li>
                                          <li>
                                                <NavLink to="/create-story/audio/">Audio</NavLink>
                                          </li>
                                    </ul>
                              </div>

                              <div className="effects-container">
                                    <h1>Effect</h1>
                                    <div className="background-picker">
                                          <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                          </ul>
                                    </div>
                              </div>
                        </div>
                        <div className="stories-block">
                              <Outlet />
                        </div>
                  </div>
            </div>
      )
}

export default StoriesWrapper;