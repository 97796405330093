import { Button } from '@chakra-ui/react'
export const Buttons = ({ action, text, loadState }) => {

  return (
    <Button className="button" variant="solid" onClick={() => {
      action()
    }}
      isLoading={loadState}
      type="submit"
    >{text}</Button>
  );
};

