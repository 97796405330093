import { gql } from "@apollo/client";
const LIKE_COMMENT = gql`
  mutation ($commentId: String!, $postId: String!) {
    likeCommentOnPost(commentId: $commentId, postId: $postId){
      authId
    }
  }
`;

export default LIKE_COMMENT;
