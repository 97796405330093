import Register from "../../containers/Register";
import { interact } from "../../components";

const SignUp = () => {
  return (
    <div className="sign-up wrapper">
      <div className="form-container">
        <interact.CloseButton where="auth" />
        <Register />
      </div>
    </div>
  );
};

export default SignUp;
