//router
import { Link } from "react-router-dom";

import { util, alert } from "../../../components";
// assets
import spinner from "../../../assets/spinner.svg";

// for the api 
import GET_STARRED from "../../../apis/profile/getStarred";
import { useQuery } from "@apollo/client";

const Star = () => {
  const { loading, error, data } = useQuery(GET_STARRED, {
    variables: {
      page_limit: 100,
      page_number: 1,
    },
  });
  if (loading) {
    return (
      <div className="load-spinner">
        <img src={spinner} alt="spinner" />
      </div>
    );
  }
    
  return (
      <>
        {error && <alert.ErrorToast errorMessage="Failed to Fetch" />}
        {
          data && data.getStarred.map(star =>(
            <Link to={`/u/${star.starred.username}`} key={star.starred.authId}>
              <util.StarUser
                username={star.starred.username}
                fullName={`${star.starred.firstName} ${star.starred.lastName}`}
                bio="the goal is to get better everday"
                photo="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
              />
            </Link>
          ))
        }
         
  
      </>
  );
};

export default Star;
