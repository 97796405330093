const TripleImage = ({ images }) => {
      return (
            <div className="post-content__tripple-image" >
                  <div>
                        <img
                              src={images[0]}
                              alt="post"
                        />
                  </div>

                  <span>
                        <div>
                              <img
                                    src={images[1]}
                                    alt="post"
                              />
                        </div>

                        <div>
                              <img
                                    src={images[2]}
                                    alt="post"
                              />
                        </div>
                  </span>
            </div>
      )
}

export default TripleImage;