import {
      Popover,
      PopoverTrigger,
      PopoverContent,
      Portal
} from '@chakra-ui/react'
import Picker from 'emoji-picker-react';

export const PickEmoji = ({ onEmojiClick }) => {
      return (
            <Popover >
                  <PopoverTrigger>
                        <span>
                              <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                              >
                                    <path
                                          d="M14.2827 13.2742L14.2817 13.2753C13.0951 14.6993 11.3532 15.5161 9.5 15.5161C7.64687 15.5161 5.9051 14.6993 4.7185 13.2756C4.71844 13.2755 4.71837 13.2754 4.71831 13.2753L14.2827 13.2742ZM14.2827 13.2742C14.4995 13.0123 14.582 12.703 14.5345 12.4036C14.4891 12.1168 14.3309 11.8758 14.1321 11.7109C13.9333 11.5461 13.6675 11.4352 13.3779 11.4432C13.0753 11.4517 12.7865 11.5895 12.57 11.8513L12.9552 12.17M14.2827 13.2742L12.9552 12.17M12.9552 12.17C13.4685 11.5494 14.4147 12.3308 13.8976 12.9552L12.9552 12.17ZM0.5 9.5C0.5 4.52816 4.52816 0.5 9.5 0.5C14.4718 0.5 18.5 4.52816 18.5 9.5C18.5 14.4718 14.4718 18.5 9.5 18.5C4.52816 18.5 0.5 14.4718 0.5 9.5ZM14.2903 7.66129C14.2903 6.70712 13.5187 5.93548 12.5645 5.93548C11.6103 5.93548 10.8387 6.70712 10.8387 7.66129C10.8387 8.61546 11.6103 9.3871 12.5645 9.3871C13.5187 9.3871 14.2903 8.61546 14.2903 7.66129ZM8.16129 7.66129C8.16129 6.70712 7.38965 5.93548 6.43548 5.93548C5.48132 5.93548 4.70968 6.70712 4.70968 7.66129C4.70968 8.61546 5.48132 9.3871 6.43548 9.3871C7.38965 9.3871 8.16129 8.61546 8.16129 7.66129Z"
                                          stroke="#0077B6"
                                    />
                              </svg>

                              <p>Emoji</p>
                        </span>
                  </PopoverTrigger>
                  <Portal>
                        <PopoverContent className="pop-over">
                              <Picker onEmojiClick={onEmojiClick} preload={true} searchPlaceholder="Search" />
                        </PopoverContent>
                  </Portal>
            </Popover>
      )
}