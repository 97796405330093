import { interact } from "..";
import { Avatar } from "@chakra-ui/react";



export const StarUser = ({ photo, username, fullName, bio}) => {
    return (
        <div className="star-user">
            <div className="star-user-info">
            <Avatar src={photo} size="sm" />
                <div className="star-user-info-details">
                    <h3>{username}</h3>
                    <p>{fullName}</p>
                    <p className="bioo">{bio}</p>
                </div>
            </div>
                <interact.StarButton />
        </div>
    );
}