//component
import { image, interact } from "..";
export const ReachingUser = ({ username, fullName, bio, photo }) => {
  return (
    <div className="reaching-user">
      <div className="user-info">
        <image.ImageFormatter
          source={photo}
          width="40px"
          height="40px"
          alt={fullName}
        />
        <div className="user-info-desc">
          <h3>{username}</h3>
          <p>{fullName}</p>
          <p>{bio}</p>
        </div>
      </div>

      <interact.StarButton />
    </div>
  );
};
