import { useEffect, useState, useRef } from "react";
import { useMutation } from "@apollo/client";
//router
import { Link } from "react-router-dom";
//recoil
import { useRecoilState } from "recoil";
import UserLocation from "../../recoil/UserLocation";
//components
import DoubleImage from "../posts/DoubleImage";
import SingleImage from "../posts/SingleImage";
import TripleImage from "../posts/TripleImage";
import QuadImage from "../posts/QuadImage";
import { Avatar, HStack, Button } from "@chakra-ui/react";
import { animation } from "..";

//api
import CREATE_COMMENT from "../../apis/posts/comments/setComment";

export const ReplyComment = ({ replyCommentData }) => {
      const { refetchFeed, setShowCommentBox, replyContainerRef, post, user, showCommentBox } = replyCommentData;
      const [userLocation] = useRecoilState(UserLocation)
      const [replyContent, setReplyContent] = useState("");

      //ref
      const commentBoxRef = useRef(null);

      const [ commentOnPost, { loading: postCommentLoading, data: postCommentData} ] = useMutation(CREATE_COMMENT);

      const commentOnPostHandler = (postId, authId) => {
            if (replyContent) {
                  commentOnPost({
                        variables: {
                              content: replyContent.substring(0, 250),
                              location: `${userLocation.address.city},${userLocation.address.country}`,
                              postId: postId,
                              userId: authId
                        },
                  });
            }
      };

      useEffect(() => {
            if (postCommentData) {
                  setReplyContent("");
                  commentBoxRef.current.innerHTML = "";
                  setShowCommentBox(false);
                  refetchFeed({
                        page_limit: 100,
                        page_number: 1,
                  });
            }
      }, [postCommentData, refetchFeed, setShowCommentBox]);

      return (
            <animation.SlideIn>
                  <div className="reply-wrapper" ref={replyContainerRef}>
                        {/* {postError && <alert.ErrorToast errorMessage={postError} />} */}
                        <div className="top">
                              <p>Reply to{" "} <Link to={`/u/${post.username}`} >{`@${post.username}`}</Link> </p>
                              <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    onClick={() => setShowCommentBox( !showCommentBox) }
                              >
                                    <path
                                          d="M25.4556 8.48511L8.485 25.4557"
                                          stroke="#252525"
                                          strokeWidth="2.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                                    <path
                                          d="M8.48584 8.48511L25.4564 25.4557"
                                          stroke="#252525"
                                          strokeWidth="2.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                              </svg>
                        </div>

                        <div className="replying-to">
                              <header>
                                    <div className="left">
                                          <Avatar
                                                src={post.profilePicture }
                                                size="md"
                                          />
                                          <div className="header-items">
                                                <span>
                                                      <h2>{post.username}</h2>
                                                      <p> {post.post.location} </p>
                                                      <p>1w.</p>
                                                </span>

                                                <div className="post-content">
                                                      <div className="post-content__text">
                                                            <p>{ post.post.content } </p>
                                                            <span>..Show more </span>
                                                      </div>
                                                      <div className="post-content__image">
                                                            {
                                                                  post.post.imageMediaItems.length === 1 ? (
                                                                        <SingleImage images={post.post.imageMediaItems} />
                                                                  ) : post.post.imageMediaItems.length === 2 ? (
                                                                        <DoubleImage images={post.post.imageMediaItems} />
                                                                  ) : post.post.imageMediaItems.length === 3 ? (
                                                                        <TripleImage images={post.post.imageMediaItems} />
                                                                  ) : post.post.imageMediaItems.length === 4 ? (
                                                                        <QuadImage images={post.post.imageMediaItems} />
                                                                  ) : null
                                                            }
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </header>
                        </div>

                        <div className="make-post-container">
                              <div className="input-box">
                                    <Avatar
                                          size="sm"
                                          src={user.profilePicture}
                                    />
                                    <div className="tweet-area">
                                          <textarea ref={commentBoxRef} onChange={e => setReplyContent(e.target.value) } placeholder="Say something"></textarea>
                                    </div>
                              </div>

                              <div className="control">
                                    <HStack spacing={4} >
                                          <span>
                                                <svg
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 19 19"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                >
                                                      <path
                                                            d="M14.2827 13.2742L14.2817 13.2753C13.0951 14.6993 11.3532 15.5161 9.5 15.5161C7.64687 15.5161 5.9051 14.6993 4.7185 13.2756C4.71844 13.2755 4.71837 13.2754 4.71831 13.2753L14.2827 13.2742ZM14.2827 13.2742C14.4995 13.0123 14.582 12.703 14.5345 12.4036C14.4891 12.1168 14.3309 11.8758 14.1321 11.7109C13.9333 11.5461 13.6675 11.4352 13.3779 11.4432C13.0753 11.4517 12.7865 11.5895 12.57 11.8513L12.9552 12.17M14.2827 13.2742L12.9552 12.17M12.9552 12.17C13.4685 11.5494 14.4147 12.3308 13.8976 12.9552L12.9552 12.17ZM0.5 9.5C0.5 4.52816 4.52816 0.5 9.5 0.5C14.4718 0.5 18.5 4.52816 18.5 9.5C18.5 14.4718 14.4718 18.5 9.5 18.5C4.52816 18.5 0.5 14.4718 0.5 9.5ZM14.2903 7.66129C14.2903 6.70712 13.5187 5.93548 12.5645 5.93548C11.6103 5.93548 10.8387 6.70712 10.8387 7.66129C10.8387 8.61546 11.6103 9.3871 12.5645 9.3871C13.5187 9.3871 14.2903 8.61546 14.2903 7.66129ZM8.16129 7.66129C8.16129 6.70712 7.38965 5.93548 6.43548 5.93548C5.48132 5.93548 4.70968 6.70712 4.70968 7.66129C4.70968 8.61546 5.48132 9.3871 6.43548 9.3871C7.38965 9.3871 8.16129 8.61546 8.16129 7.66129Z"
                                                            stroke="#0077B6"
                                                      />
                                                </svg>

                                                <p> Emoji </p>
                                          </span>
                                          <span>
                                                <svg
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 20 20"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                >
                                                      <mask
                                                            id="mask0_6732_231"
                                                            maskUnits="userSpaceOnUse"
                                                            x="0"
                                                            y="0"
                                                            width="20"
                                                            height="20"
                                                      >
                                                            <path
                                                                  fillRule="evenodd"
                                                                  clipRule="evenodd"
                                                                  d="M0.000427246 0.000183105H19.96V19.95H0.000427246V0.000183105Z"
                                                                  fill="white"
                                                            />
                                                      </mask>
                                                      <g mask="url(#mask0_6732_231)">
                                                            <path
                                                                  fillRule="evenodd"
                                                                  clipRule="evenodd"
                                                                  d="M5.65043 1.5C3.12943 1.5 1.50043 3.227 1.50043 5.899V14.051C1.50043 16.724 3.12943 18.45 5.65043 18.45H14.3004C16.8274 18.45 18.4604 16.724 18.4604 14.051V5.899C18.4604 3.227 16.8274 1.5 14.3004 1.5H5.65043ZM14.3004 19.95H5.65043C2.27043 19.95 0.000427246 17.579 0.000427246 14.051V5.899C0.000427246 2.371 2.27043 0 5.65043 0H14.3004C17.6854 0 19.9604 2.371 19.9604 5.899V14.051C19.9604 17.579 17.6854 19.95 14.3004 19.95Z"
                                                                  fill="#0077B6"
                                                            />
                                                      </g>
                                                      <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M3.28144 15.1804C3.09544 15.1804 2.91044 15.1124 2.76544 14.9744C2.46444 14.6904 2.45244 14.2144 2.73744 13.9154L4.26544 12.3024C5.07444 11.4434 6.43944 11.4014 7.30244 12.2114L8.26044 13.1834C8.52744 13.4534 8.96144 13.4584 9.22944 13.1944C9.33044 13.0754 11.5084 10.4304 11.5084 10.4304C11.9224 9.92837 12.5064 9.61837 13.1554 9.55437C13.8054 9.49737 14.4364 9.68637 14.9394 10.0994C14.9824 10.1344 15.0214 10.1684 17.2174 12.4234C17.5064 12.7194 17.5014 13.1944 17.2044 13.4834C16.9084 13.7744 16.4324 13.7654 16.1434 13.4694C16.1434 13.4694 14.0944 11.3664 13.9484 11.2244C13.7934 11.0974 13.5444 11.0234 13.2994 11.0474C13.0504 11.0724 12.8264 11.1914 12.6674 11.3844C10.3434 14.2034 10.3154 14.2304 10.2774 14.2674C9.41944 15.1094 8.03444 15.0954 7.19144 14.2354C7.19144 14.2354 6.26144 13.2914 6.24544 13.2724C6.01444 13.0584 5.60244 13.0724 5.35544 13.3334L3.82544 14.9464C3.67744 15.1024 3.47944 15.1804 3.28144 15.1804Z"
                                                            fill="#0077B6"
                                                      />
                                                      <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6.55763 6.12891C6.00463 6.12891 5.55463 6.57891 5.55463 7.13291C5.55463 7.68691 6.00463 8.13791 6.55863 8.13791C7.11263 8.13791 7.56363 7.68691 7.56363 7.13291C7.56363 6.57991 7.11263 6.12991 6.55763 6.12891ZM6.55863 9.63791C5.17763 9.63791 4.05463 8.51391 4.05463 7.13291C4.05463 5.75191 5.17763 4.62891 6.55863 4.62891C7.94063 4.62991 9.06363 5.75391 9.06363 7.13291C9.06363 8.51391 7.93963 9.63791 6.55863 9.63791Z"
                                                            fill="#0077B6"
                                                      />
                                                </svg>

                                                <p> Media </p>
                                          </span>
                                          <span>
                                                <svg
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 18 22"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                >
                                                      <path
                                                            d="M8.99982 21V17.8391"
                                                            stroke="#0077B6"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                      />
                                                      <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M8.99976 13.8481V13.8481C6.75656 13.8481 4.93768 12.0218 4.93768 9.76819V5.08095C4.93768 2.82732 6.75656 1 8.99976 1C11.244 1 13.0618 2.82732 13.0618 5.08095V9.76819C13.0618 12.0218 11.244 13.8481 8.99976 13.8481Z"
                                                            stroke="#0077B6"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                      />
                                                      <path
                                                            d="M17 9.8006C17 14.2394 13.4188 17.8383 8.99948 17.8383C4.58117 17.8383 1 14.2394 1 9.8006"
                                                            stroke="#0077B6"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                      />
                                                </svg>

                                                <p> Audio </p>
                                          </span>
                                          <span>
                                                <svg
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 17 20"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                >
                                                      <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M8.25049 6.5C7.28549 6.5 6.50049 7.285 6.50049 8.251C6.50049 9.216 7.28549 10 8.25049 10C9.21549 10 10.0005 9.216 10.0005 8.251C10.0005 7.285 9.21549 6.5 8.25049 6.5ZM8.25049 11.5C6.45849 11.5 5.00049 10.043 5.00049 8.251C5.00049 6.458 6.45849 5 8.25049 5C10.0425 5 11.5005 6.458 11.5005 8.251C11.5005 10.043 10.0425 11.5 8.25049 11.5Z"
                                                            fill="#0077B6"
                                                      />
                                                      <mask
                                                            id="mask0_6732_720"
                                                            maskUnits="userSpaceOnUse"
                                                            x="0"
                                                            y="0"
                                                            width="17"
                                                            height="20"
                                                      >
                                                            <path
                                                                  fillRule="evenodd"
                                                                  clipRule="evenodd"
                                                                  d="M0 0H16.4995V19.5H0V0Z"
                                                                  fill="white"
                                                            />
                                                      </mask>
                                                      <g mask="url(#mask0_6732_720)">
                                                            <path
                                                                  fillRule="evenodd"
                                                                  clipRule="evenodd"
                                                                  d="M8.24951 1.5C4.52751 1.5 1.49951 4.557 1.49951 8.313C1.49951 13.092 7.12351 17.748 8.24951 17.996C9.37551 17.747 14.9995 13.091 14.9995 8.313C14.9995 4.557 11.9715 1.5 8.24951 1.5V1.5ZM8.24951 19.5C6.45551 19.5 -0.000488281 13.948 -0.000488281 8.313C-0.000488281 3.729 3.70051 0 8.24951 0C12.7985 0 16.4995 3.729 16.4995 8.313C16.4995 13.948 10.0435 19.5 8.24951 19.5V19.5Z"
                                                                  fill="#0077B6"
                                                            />
                                                      </g>
                                                </svg>

                                                <p> Location </p>
                                          </span>
                                    </HStack>
                                    <HStack>
                                          <p className={replyContent.length > 250 ? "counter counter-highlight" : "counter" } >
                                                { replyContent.length }
                                          </p>

                                          <Button variant="solid" size="sm" onClick={() => { commentOnPostHandler( post.postId, post.post.authId)}}
                                                isLoading={ postCommentLoading}
                                          >
                                                Reply
                                          </Button>
                                    </HStack>
                              </div>
                        </div>
                  </div>
            </animation.SlideIn>
      )
}