//state
import { useRecoilState } from "recoil";
import UserData from "../../recoil/UserRecoil";
import Username from "../../recoil/UsernameRecoil";
import { UsernameUpdateState } from "../../recoil/UsernameRecoil";
//components
import { nav, image } from "../../components";
import { Input } from "@chakra-ui/react";
//router
import { Link, useNavigate } from "react-router-dom";


const Explore = () => {
  const navigate = useNavigate();
  const [user] = useRecoilState(UserData);
  const [navUsername] = useRecoilState(Username);
  const [usernameUpdateState] = useRecoilState(UsernameUpdateState);

  return (
    <div className="explore">
      <nav className="search-nav">
        <Link
          to={usernameUpdateState ? `/u/${navUsername}` : `/u/${user.username}`}
        >
          <image.ImageFormatter
            source="https://images.unsplash.com/photo-1650649497336-68102b2940a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            height="30px"
            width="30px"
            alt="User Profile"
          />
        </Link>

        <div className="search-wrapper">
          <Input
            size="md"
            placeholder="Search Reachme"
            _placeholder={{ opacity: 0.5, color: "gray.500", fontSize: "sm" }}
            type="search"
            variant="filled"
            onClick={() => {
              navigate("/explore/search");
            }}
          />
        </div>
      </nav>
      <nav.BottomNav />
    </div>
  );
};

export default Explore;
