import axios from "axios";

async function UploadImagesToServer(target, changeImage) {
  const formData = new FormData();
  formData.append("file", target);
  changeImage(URL.createObjectURL(target));

  try {
    let res = await axios.post("http://185.3.95.146:4600/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // "redirect": "follow",
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log(res)
  } catch (err) {
    console.log(err);
  }
}

export default UploadImagesToServer;

