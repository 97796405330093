import { gql } from "@apollo/client";
const GET_POST_FEED = gql`
query($page_limit: Int!, $page_number: Int!) {
    getPostFeed(page_limit: $page_limit, page_number: $page_number) {
    created_at,
    feedOwnerId,
    firstName,
    lastName,
    like {
      authId
    }
    location,
    post {
      audioMediaItem,
      authId,
      commentOption,
      content,
      created_at,
      edited,
      hashTags,
      imageMediaItems,
      location
      mentionList,
      nComments,
      nLikes,
      nShares,
      videoMediaItem,
      }
      postId
    postOwnerId,
    profilePicture,
    profileSlug,
    reachingRelationship,
    username,
    verified,
    vote {
      voteType
    }
  }
  }
`;

export default GET_POST_FEED;
