import { interact, util } from ".."
export const SearchModal = ({ setCloseModal, searchModalRef, value }) => {
      const { loading, data } = value;
      return (
            <div className="search-modal" ref={searchModalRef}>
                  <header>
                        <p>Recent</p>
                        <interact.CloseModal setCloseModal={setCloseModal} />
                  </header>
                  {loading ? (
                        <div className="search-modal-spinner">
                              <util.Spinner />
                        </div>
                  ) : (
                        <div className="search">
                              {data ? (
                                    data.searchProfile.length !== 0 ? (
                                          data.searchProfile.map((user) => {
                                                return (
                                                      <util.SearchedUser
                                                            key={user.username}
                                                            userName={user.username}
                                                            firstName={user.firstName}
                                                            lastName={user.lastName}
                                                            photo={user.profilePicture}
                                                            setCloseModal={setCloseModal}
                                                      />
                                                );
                                          })
                                    ) : (
                                          <util.ErrorText message="Couldn't fetch the particular user" />
                                    )
                              ) : null}
                        </div>
                  )}

            </div>
      )
}