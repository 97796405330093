import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SimpleLoader = ({ amount }) => {
      const loadCards = Array(amount).fill(1);
      return loadCards.map((_, i) => (
            <div className="simple-loader" key={i}>
                  <Skeleton circle width={35} height={35} />
                  <div>
                        <Skeleton count={1} height={35} />
                  </div>

            </div>
      ));
};
