//react
import { useEffect } from "react";
//apollo
import { useLazyQuery } from "@apollo/client";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//router
import { useNavigate } from "react-router-dom";
//api
import INITIATE_FORGOT_PASSWORD from "../apis/auth/AuthInitiateForgotPassword";


//components
import { interact, alert } from "../components";


const Forgot=()=>{
    let navigate = useNavigate();

    const [initiatePasswordReset, { loading,data }] = useLazyQuery(INITIATE_FORGOT_PASSWORD);
  
    useEffect(()=>{
      if(data) {
          if(data.initiatePasswordReset === true){
            const timer = setTimeout(() => {
                navigate(`/auth/reset-password`);
              }, 2000);
              return () => clearTimeout(timer);
          }
      }
    }, [data, navigate]);
  
  
    const formik = useFormik({
      initialValues: {
        forgotEmail: "",
      },
      validationSchema: Yup.object({
        forgotEmail: Yup.string().email("Invalid email address").required("Required"),
      }),
      onSubmit: (values) => {
        const { forgotEmail } = values;
        initiatePasswordReset({ variables: { 'email': forgotEmail} })
      },
    });
  
    return (
      <div className="forgot">
        <svg
          width="24"
          height="24"
          viewBox="0 0 37 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_3897_8920)">
            <path
              d="M36.3 32.7105C34.1776 33.6469 32.18 34.5208 30.1824 35.426C29.8702 35.582 29.6518 35.3948 29.4333 35.2699C27.6854 34.2399 25.9063 33.1787 24.1584 32.1487C16.3553 27.5293 8.52098 22.9098 0.717885 18.2904C0.187274 17.9783 0 17.6662 0.0312124 17.0419C0.0624248 15.5125 0.0312124 13.9831 0.0624248 12.4849C0.0624248 12.2352 0 11.9855 0.12485 11.7358C0.624248 11.8607 1.09243 12.1728 1.56062 12.4537C6.64824 15.4189 11.7359 18.3841 16.8235 21.3492C17.3541 21.6613 17.8535 21.7862 18.4465 21.755C19.9135 21.6613 21.3181 21.4116 22.6602 20.8498C25.4381 19.695 26.9987 17.6037 27.3108 14.6074C27.4357 13.5149 27.3733 12.4225 27.1548 11.3301C26.6554 8.92672 25.2508 7.21004 23.0347 6.14882C21.3181 5.30609 19.5077 5.02517 17.6662 4.99396C13.8271 4.96275 9.98796 4.99396 6.14884 4.99396C5.49338 4.99396 5.49338 4.99396 5.49338 5.68063C5.49338 6.99155 5.49338 8.27126 5.49338 9.58217C5.49338 9.83187 5.52459 10.0816 5.43095 10.3001C5.18125 10.3313 5.02519 10.1752 4.86913 10.0816C3.43336 9.27005 1.99759 8.45853 0.561823 7.64701C0.156062 7.45974 0 7.17882 0 6.74185C0.0312124 4.68184 0 2.62183 0.0312124 0.593025C0.0312124 0.0312029 0.0312124 -9.41893e-06 0.593035 -9.41893e-06C6.64824 -9.41893e-06 12.7347 -0.0312217 18.7899 0.0312029C21.3805 0.0624152 23.8775 0.655449 26.1872 1.87273C30.0887 3.93274 32.3048 7.17882 32.8354 11.5798C33.1475 14.2016 32.8354 16.761 31.7118 19.1644C30.3072 22.0983 27.9663 24.0335 25.0323 25.3132C24.8138 25.4068 24.6266 25.5005 24.4081 25.5941C24.3769 25.5941 24.3769 25.6253 24.3144 25.7189C28.2784 28.0287 32.2424 30.3384 36.3 32.7105Z"
              fill="#0C78B8"
            />
            <path
              d="M2.34079e-05 23.6902C8.2713 28.4657 16.3553 33.21 24.4705 37.923C24.4081 38.1415 24.252 38.1727 24.1272 38.2039C22.3481 39.0155 20.569 39.7958 18.8211 40.6073C18.4465 40.7946 18.1656 40.7633 17.7911 40.5449C12.048 37.1115 6.27371 33.6781 0.499421 30.2448C0.0936605 30.0263 -0.031189 29.7454 -0.031189 29.3084C0.0312358 27.5605 2.34079e-05 25.719 2.34079e-05 23.6902Z"
              fill="#0C78B8"
            />
          </g>
          <defs>
            <clipPath id="clip0_3897_8920">
              <rect width="36.3" height="40.7633" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1>Forgot Password</h1>
        <p className="forgot-desc">
          Find your reachme account by entering your email address
        </p>
  
        <form>
          {data? data.initiatePasswordReset? <alert.ErrorToast errorMessage='Password reset link sent to your email' color="#1C8B43" /> : <alert.ErrorToast errorMessage='No user found with this email' /> :null}
  
          <interact.Input
            type="email"
            placeholder="Enter the email linked to your account"
            id="forgotEmail"
            name="forgotEmail"
            change={formik.handleChange}
            blur={formik.handleBlur}
            value={formik.values.forgotEmail}
          />
          {formik.touched.forgotEmail && formik.errors.forgotEmail ? (
            <div className="formik-error">{formik.errors.forgotEmail}</div>
          ) : null}
  
  
          <interact.Buttons
            loadState={loading}
            text="Search"
            type="submit"
            action={() => {
              formik.handleSubmit();
            }}
          />
        </form>
      </div>
    );
}

export default Forgot;