//assets
import spinner from "../../assets/spinner.svg";
//
import { useMutation } from "@apollo/client";
import DELETE_POST from "../../apis/posts/setDeletePost";
import SAVE_POST from "../../apis/posts/setSavePost";

export const PostKebabMenu = ({ kebabData }) => {
      const { refetchFeed, post, kebabMenuRef, user } = kebabData;
      const [deletePost, { loading: loadingDelete, data: dataDelete }] = useMutation(DELETE_POST);
      const [savePost, { loading: loadingSave }] = useMutation(SAVE_POST);

      if (dataDelete) {
            refetchFeed({ page_limit: 100, page_number: 1 });
      }

      return (
            <div className="kebab-menu" ref={kebabMenuRef}>
                  <p>Share Post </p>
                  <p
                        onClick={() => {
                              savePost(
                                    {
                                          variables: {
                                                postId: post.postId,
                                          },
                                    }
                              );
                        }}
                  > Save Post
                  </p>
                  <p> Report </p>
                  <p> Reach </p>
                  <p> Star User </p>
                  <p> Copy Link </p>
                  <p> Turn off commenting </p>
                  <p> Download </p>

                  {user.authId !== post.post.authId ? null : (
                        <p
                              onClick={() => {
                                    deletePost(
                                          {
                                                variables: {
                                                      postId: post.postId,
                                                },
                                          }
                                    );
                              }}
                        >Delete</p>
                  )}


                  {loadingSave || loadingDelete ?
                        <div className="spinner">
                              <img src={ spinner } alt="spinner" />
                        </div> : null
                  }
            </div>
      )
}