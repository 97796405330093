import { gql } from "@apollo/client";
const REACH_USERS = gql`
  mutation ($userIdToReach: String!) {
    reachUser(userIdToReach: $userIdToReach) {
      reacherId
      reachingId
    }
  }
`;

export default REACH_USERS;
