import { nav } from "../components";
const Moments = (props) => {
  return (
    <>
      {/* <h1>Moments</h1> */}
      <nav.BottomNav />
    </>
  );
};

export default Moments;
