import { gql } from "@apollo/client";
const GET_COMMENTS = gql`
  query ($page_limit: Int!, $page_number: Int!, $postId: String!) {
    getAllCommentsOnPost(
      page_limit: $page_limit
      page_number: $page_number
      postId: $postId
    ) {
      authId
      commentId
      commentSlug
      content
      created_at
      nComments
      nLikes
      postId
      profile {
        firstName
        lastName
        location
        profileSlug
        profilePicture
        username
        verified
      }
    }
  }
`;

export default GET_COMMENTS;
