import { gql } from "@apollo/client";

const GET_PERSONAL_COMMENTS = gql`
query getPersonalComments($page_limit: Int!, $page_number: Int!){
  getPersonalComments(page_limit: $page_limit, page_number:$page_number){
    authId,
    postId,
    content,
    created_at,
    profile{
      firstName,
      lastName,
      location,
      profilePicture,
      profileSlug,
      username,
      verified
    }
  }
}`;

export default GET_PERSONAL_COMMENTS;