import { nav } from "..";
export const NotFound = ({notFoundUser, header="Sorry, this page isn't available.", body="The link you followed seems to be broken, we couldn't find the particular user"}) => {
  return (
    <>
      <div className="page-not-found">
        <h1>{header}</h1>
        <p>{body} <span>{notFoundUser}</span></p>
      </div>
      <nav.BottomNav />
    </>
  );
};

