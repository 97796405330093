import { Button } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import REACH_USERS from "../../apis/subtleInteractions/setReachUsers";

export const ReachButton = ({ userId }) => {
  const [reachUser, { loading, }] = useMutation(REACH_USERS);

//   if(error) console.log(error.message)

  const reachUserHandler = () => {
    reachUser({
      variables: {
        userIdToReach: userId,
      },
    });
  };
  return (
    <Button
      className="edit-profile-btn reach-btn"
      variant="outline"
      size="sm"
      onClick={reachUserHandler}
      isLoading={loading? true: false}
    >
      Reach
    </Button>
  );
};

