import { NavLink } from "react-router-dom";

export const CloseButton = ({ where }) => {
  return (
    <NavLink to={`/${where}`}>
      <svg
        className="close-button"
        width="24"
        height="24"
        viewBox="0 0 34 34"
        fill="none"
      >
        <path
          d="M25.4556 8.48511L8.485 25.4557"
          stroke="#252525"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.48584 8.48511L25.4564 25.4557"
          stroke="#252525"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </NavLink>
  );
};
