export const CloseModal = ({ setCloseModal }) => {
      return (
            <svg
                  onClick={() => {
                        setCloseModal(false)
                  }}
                  className="close-button"
                  width="22"
                  height="22"
                  viewBox="0 0 34 34"
                  fill="none"
            >
                  <path
                        d="M25.4556 8.48511L8.485 25.4557"
                        stroke="#252525"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                  />
                  <path
                        d="M8.48584 8.48511L25.4564 25.4557"
                        stroke="#252525"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                  />
            </svg>
      )
}