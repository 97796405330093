import { gql } from "@apollo/client/core";

const GET_USER_BY_USERNAME = gql`
  query ($username: String!) {
    getUserByUsername(username: $username) {
      authId
      bio
      coverPicture
      dateOfBirth
      email
      firstName
      gender
      lastName
      location
      nReachers
      nReaching
      nStaring
      phone
      profilePicture
      profileSlug
      showContact
      showLocation
      username
      verified
    }
  }
`;

export default GET_USER_BY_USERNAME;