import { useState, useRef } from "react";
//router
import { NavLink, Link } from "react-router-dom";
//recoil
import { useRecoilState } from "recoil";
import UserData from "../../recoil/UserRecoil";
import Username from "../../recoil/UsernameRecoil";
import { UsernameUpdateState } from "../../recoil/UsernameRecoil";
//api
import { useLazyQuery } from "@apollo/client"
import GET_SEARCH_PROFILE from "../../apis/profile/getSearchProfile";
//components
import { Avatar, Input, useOutsideClick } from "@chakra-ui/react";
import { modals } from "..";



export const NavBar = (props) => {
  const [user] = useRecoilState(UserData);
  const [navUsername] = useRecoilState(Username);
  const [usernameUpdateState] = useRecoilState(UsernameUpdateState);
  const [searchQuery, setSearchQuery] = useState("");
  const searchModalRef = useRef();
  const [showSearchModal, setShowSearchModal] = useState(false);

  useOutsideClick({
    ref: searchModalRef,
    handler: () => setShowSearchModal(false)
  })

  const [searchProfile, { loading, data, error }] = useLazyQuery(GET_SEARCH_PROFILE);
  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <nav className="navbar">
      <div className="navbar__container wrapper">
        <div className="navbar__left">
          <Link to="/">
            <h1>Reachme</h1>
          </Link>

          <div className="navbar__search-wrapper">
            <span className="navbar__search" onClick={() => {
              setShowSearchModal(true)
            }}>
              <svg width="14" height="14" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.7113 19.4946L19.7479 19.5309L19.7879 19.5633L22.8535 22.0378L22.9218 22.0929C23.0522 22.3258 23.0195 22.6288 22.824 22.8265C22.6007 23.0521 22.2465 23.0577 22.0169 22.8432L19.4983 19.9567L19.4984 19.9567L19.4932 19.9509L19.4222 19.8707C19.3884 19.822 19.3692 19.7623 19.3692 19.6996C19.3692 19.6202 19.4 19.546 19.4519 19.4924C19.5258 19.4213 19.6382 19.422 19.7113 19.4946ZM10.2932 1C12.7544 1 15.117 1.98814 16.8607 3.75055C18.6047 5.51332 19.5863 7.90641 19.5863 10.4039C19.5863 15.6077 15.4156 19.8078 10.2932 19.8078C5.17076 19.8078 1 15.6077 1 10.4039C1 5.20016 5.17076 1 10.2932 1Z"
                  stroke="#333333"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                />
              </svg>

              <Input type="search" placeholder="Search" value={searchQuery}
                onChange={(e) => {
                  onChange(e);
                  if (e.target.value !== "") {
                    searchProfile({
                      variables: {
                        limit: 20,
                        name: e.target.value,
                        pageNumber: 1,
                      },
                    });
                  }
                }} />
            </span>
            {showSearchModal && <modals.SearchModal setCloseModal={setShowSearchModal} searchModalRef={searchModalRef} value={{ loading, data, error }} />}
          </div>
        </div>
        <ul className="navbar__links">
          <li>
            <NavLink to="/">
              <svg
                width="20"
                height="20"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714V19.7714"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
          </li>

          <li>
            <NavLink to="/chat/">
              <svg width="20" height="20" viewBox="0 0 22 20" fill="none">
                <path
                  className="chat-icon"
                  d="M16.9026 6.8512L12.4593 10.4642C11.6198 11.1302 10.4387 11.1302 9.59919 10.4642L5.11841 6.8512"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#252525"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#252525"
                />
              </svg>
            </NavLink>
          </li>

          <li>
            <NavLink to="/moments">
              <svg width="20" height="20" viewBox="0 0 22 22" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 1.5C16.2459 1.5 20.5 5.75315 20.5 11C20.5 16.2469 16.2459 20.5 11 20.5C5.75315 20.5 1.5 16.2469 1.5 11C1.5 5.75315 5.75315 1.5 11 1.5Z"
                  stroke="#130F26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 10.9951C14 10.184 9.84253 7.58912 9.37091 8.0557C8.8993 8.52228 8.85395 13.424 9.37091 13.9346C9.88788 14.4469 14 11.8063 14 10.9951Z"
                  stroke="#130F26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
          </li>

          <li>
            <NavLink to="/notifications/">
              <svg width="20" height="20" viewBox="0 0 20 22" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 16.8476C15.6392 16.8476 18.2481 16.1242 18.5 13.2205C18.5 10.3188 16.6812 10.5054 16.6812 6.94511C16.6812 4.16414 14.0452 1 10 1C5.95477 1 3.31885 4.16414 3.31885 6.94511C3.31885 10.5054 1.5 10.3188 1.5 13.2205C1.75295 16.1352 4.36177 16.8476 10 16.8476Z"
                  stroke="#130F26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.3889 19.8572C11.0247 21.3719 8.89672 21.3899 7.51953 19.8572"
                  stroke="#130F26"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={
                usernameUpdateState
                  ? `/u/${navUsername}/`
                  : `/u/${user.username}/`
              }
            >
              <Avatar size="sm" src={user.profilePicture} />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
