import Forgot from "../../containers/Forgot";
import { interact } from "../../components";

const ForgotPassword = () => {
    return(
        <div className="forgot-password-container wrapper">
            <div className="form-container">
                <interact.CloseButton where="auth"/>
                <Forgot />
            </div>
        </div>
    )
}

export default ForgotPassword;