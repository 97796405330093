import { gql } from '@apollo/client'

const SET_SEND_CHAT_MESSAGE = gql`
  mutation sendChatMessage(
    $senderId: String!
    $receiverId: String!
    $type: MessageType!
    $threadId: String
    $value: String
  ) {
    sendChatMessage(
      data: {
        senderId: $senderId
        receiverId: $receiverId
        type: $type
        threadId: $threadId
        value: $value
      }
    ) {
      _id
    }
  }
`

export default SET_SEND_CHAT_MESSAGE
