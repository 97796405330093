import { useState, useEffect } from "react";
import { motion} from "framer-motion";

export const ErrorToast = (props) => {
  const [errorComponent, setErrorComponent] = useState(
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{opacity: 0}}
        className={`error-toast`}
        style={{backgroundColor: props.color}}
      >
        <p>{props.errorMessage}</p>
        {props.color === "#1C8B43"?<svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" color="#ffffff"><path d="M0 0h24v24H0z" fill="none"></path><path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z"></path></svg>:         <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="currentColor"
          color="#fff"
        >
          <path d="M0 0h24v24H0V0z" fill="none"></path>
          <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        </svg>}
      </motion.div>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorComponent(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return errorComponent;
};
