const SingleImage = ({ images }) => {
      return (
            <div className="post-content__double-image" >
                  <div>
                        <img
                              src={images[0]}
                              alt="post"
                        />
                  </div>
            </div>
      )
}

export default SingleImage;