//react
import { useEffect } from "react"
//apis
import { useQuery } from "@apollo/client"
import GET_PERSONAL_COMMENTS from "../../../apis/profile/getPersonalComments"
//components
import { Avatar } from "@chakra-ui/react"
import { util, skeletons } from "../.."
import { Link } from "react-router-dom"

export const Comment = () => {
      const { data, loading, error, refetch } = useQuery(GET_PERSONAL_COMMENTS, {
            variables: {
                  "page_limit": 100,
                  "page_number": 1
            }
      })

      useEffect(() => {
            refetch();
      })

      if (loading) return <skeletons.CommonPostLoader amount={10} />
      if (error) return <util.ErrorText message="Couldn't fetch comments right now!" />

      return data && data.getPersonalComments.map((comment, index) => {
            return (
                  <div className="profile_comment" key={index}>
                        <div className="header">
                              <Avatar size="sm" src={comment.profile.profilePicture} />
                              <span>
                                    <h2>{comment.profile.username}</h2>
                                    <p>comment on <span>@jason</span></p>
                                    <p>22 jan</p>
                              </span>
                        </div>
                        <svg
                              className="kebab"
                              width="16"
                              height="16"
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                        >
                              <g clipPath="url(#clip0_3527_6484)">
                                    <path
                                          d="M12.0568 13.0481C12.0568 13.6004 12.5556 14.0481 13.1708 14.0481C13.786 14.0481 14.2848 13.6004 14.2848 13.0481C14.2848 12.4958 13.786 12.0481 13.1708 12.0481C12.5556 12.0481 12.0568 12.4958 12.0568 13.0481Z"
                                          stroke="#333333"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                                    <path
                                          d="M19.8548 13.0481C19.8548 13.6004 20.3535 14.0481 20.9688 14.0481C21.584 14.0481 22.0828 13.6004 22.0828 13.0481C22.0828 12.4958 21.584 12.0481 20.9688 12.0481C20.3535 12.0481 19.8548 12.4958 19.8548 13.0481Z"
                                          stroke="#333333"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                                    <path
                                          d="M4.25884 13.0481C4.25884 13.6004 4.75759 14.0481 5.37283 14.0481C5.98807 14.0481 6.48682 13.6004 6.48682 13.0481C6.48682 12.4958 5.98807 12.0481 5.37283 12.0481C4.75759 12.0481 4.25884 12.4958 4.25884 13.0481Z"
                                          stroke="#333333"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                    />
                              </g>
                              <defs>
                                    <clipPath id="clip0_3527_6484">
                                          <rect
                                                width="6"
                                                height="21.1658"
                                                fill="white"
                                                transform="translate(23.498 9.74878) rotate(90)"
                                          />
                                    </clipPath>
                              </defs>
                        </svg>
                        <Link to={`/reach/${comment.postId}`} >
                              <div className="content">
                                    <p>{comment.content}</p>
                              </div>
                        </Link>
                  </div>
            )
      })
}
