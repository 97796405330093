import { gql } from "@apollo/client";
const CREATE_COMMENT = gql`
  mutation commentOnPost( $content: String!, $location: String!, $postId: String!, $userId: String!) {
    commentOnPost(commentBody: {content: $content, location: $location, postId:$postId, userId:$userId }) {
      authId
    }
  }
`;

export default CREATE_COMMENT;
