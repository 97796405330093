import { gql } from "@apollo/client";
const CREATE_POST = gql`
  mutation createPost($commentOption: String!, $content: String!, $location: String!) {
    createPost(postBody: { commentOption: $commentOption, content: $content, location: $location }) {
      authId
    }
  }
`;

export default CREATE_POST;
