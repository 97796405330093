
//components
import { Avatar } from "@chakra-ui/react";
import { interact } from "..";

export const ReachUser = ({username, fullName, bio, photo}) => {
    return (
        <div className="reach-user">
            <div className="user-info">
                <Avatar src={photo} size="sm" />
                <div className="user-info-desc">
                    <h3>{username}</h3>
                    <p>{fullName}</p>
                    <p>{bio}</p>
                </div>
            </div>

            <interact.ReachButton />
        </div>
    )
}
