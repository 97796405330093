//router
import { useParams } from "react-router-dom";
//apollo
import { useMutation } from "@apollo/client/react";
//state
import { useState } from "react";
import { useRecoilState } from "recoil";
import Username from "../../recoil/UsernameRecoil";
import UserData from "../../recoil/UserRecoil";
import { UsernameUpdateState } from "../../recoil/UsernameRecoil";
//apis
import SET_USERNAME from "../../apis/profile/setUsername";
import UPDATE_USER from "../../apis/profile/setUpdateUser";
//utilities
import UploadImagesToServer from "../../utils/uploadImages";
//components
import { interact, image, util, alert } from "../../components";
import { Switch, Button, Avatar } from "@chakra-ui/react";
// assets
import nocover from "../../assets/nocover.png"

const EditProfile = () => {
  let params = useParams();
  const [user] = useRecoilState(UserData);
  // eslint-disable-next-line no-unused-vars
  const [navUsername, setNavUsername] = useRecoilState(Username);
  // eslint-disable-next-line no-unused-vars
  const [usernameUpdateState, setUsernameUpdateState] =
    useRecoilState(UsernameUpdateState);
  const [userName, setUserName] = useState(user.username);
  const [userBio, setUserBio] = useState(user.bio);
  const [contactInfo, setContactInfo] = useState(user.showContact);
  const [showLocation, setShowLocation] = useState(user.showLocation);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [setUsername, { loading: usernameLoading }] = useMutation(SET_USERNAME);
  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER);

  // console.log(navUsername, usernameUpdateState);
  const onChange = (e) => {
    if (e.target.name === "username") {
      setUserName(e.target.value);
    } else if (e.target.name === "bio") {
      setUserBio(e.target.value);
    }
  };

  async function onSubmit() {
    const updateUserObj = {};

    try {
      if (userName !== user.username) {
        await setUsername({
          variables: { username: userName.replace(/ /g, "") },
        });
        setNavUsername(userName);
        if (
          (userBio !== user.bio && userBio !== null) ||
          (contactInfo !== user.showContact && contactInfo !== null) ||
          (showLocation !== user.showLocation && showLocation !== null)
        ) {
          Object.assign(updateUserObj, { bio: userBio });
          Object.assign(updateUserObj, { showContact: contactInfo });
          Object.assign(updateUserObj, { showLocation: showLocation });
          updateUser({ variables: { userData: updateUserObj } });
        }
      } else {
        if (
          (userBio !== user.bio && userBio !== null) ||
          (contactInfo !== user.showContact && contactInfo !== null) ||
          (showLocation !== user.showLocation && showLocation !== null)
        ) {
          Object.assign(updateUserObj, { bio: userBio });
          Object.assign(updateUserObj, { showContact: contactInfo });
          Object.assign(updateUserObj, { showLocation: showLocation });
          await updateUser({ variables: { userData: updateUserObj } });
          setNavUsername(user.username);
        }
      }
      setIsSuccess(true);
      setIsError(false);
      setUsernameUpdateState(true);
    } catch (err) {
      setIsError(true);
      setIsSuccess(false);
      setErrorMessage(err.message);
    }
  }


  return params.username === user.username ? (
    <div className="edit-profile-container ">
      <div className="form-container">
        {isSuccess && (
          <alert.ErrorToast
            errorMessage="Profile Updated Successfully"
            color="#1C8B43"
          />
        )}
        {isError && <alert.ErrorToast errorMessage={errorMessage} />}
        <div className="form-nav">
          <span>
            <interact.CloseButton
              where={isSuccess ? `u/${userName}` : `u/${user.username}`}
            />
            <p>Edit Profile</p>
          </span>

          <Button
            type="submit"
            isLoading={usernameLoading || updateUserLoading}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
        <form>
          <div className="edit-images-container">
            <div className="edit-cover-photo-container">
              <img src={nocover} alt="edit profile cover" />
              <image.ImageUploader
                width="25px"
                height="25px"
                uploadImage={UploadImagesToServer}
                // changeImage={ }
              />
            </div>
            <div className="edit-profile-photo-container">
              <Avatar className="image-formatter-container" size="lg" src="" />
              <image.ImageUploader
                width="20px"
                height="20px"
                marginBottom=".5em"
                uploadImage={UploadImagesToServer}
                // changeImage={setProfileImage}
              />
            </div>
          </div>

          <div className="inputs">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={`${user.firstName} ${user.lastName}`}
              readOnly
            />

            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              value={userName}
              onChange={onChange}
            />

            <label htmlFor="bio">Bio</label>
            <input
              type="text"
              name="bio"
              id="bio"
              maxLength={200}
              value={userBio ? userBio : ""}
              onChange={onChange}
            />
          </div>

          <div className="profile-display">
            <h2>Profile Display</h2>
            <div className="profile-display-fields">
              <p>Contact info</p>

              <Switch
                size="sm"
                value={contactInfo}
                isChecked={contactInfo ? true : false}
                onChange={() => {
                  setContactInfo(!contactInfo);
                }}
              />
            </div>
            <div className="profile-display-fields">
              <p>Show location</p>

              <Switch
                size="sm"
                value={showLocation}
                isChecked={showLocation ? true : false}
                onChange={() => {
                  setShowLocation(!showLocation);
                }}
              />
            </div>
          </div>

          <div className="personal-information">
            <h2>Personal Information settings</h2>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <util.NotFound
      header="Sorry, you can't edit this page"
      body="You can not edit an account that doesn't belong to you. You're seeing this because you tried editing an account that belongs to"
      notFoundUser={params.username}
    />
  );
};

export default EditProfile;
