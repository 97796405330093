//react
import { useState, useEffect } from "react";
//router
import { Outlet, NavLink } from "react-router-dom";
//components
import { nav, util } from "../../components";

const Chat = (props) => {
  const [hide, setHide] = useState(false);
  const [hideMessageContainer, setHideMessageContainer] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 900) {
      setHideMessageContainer(true);
    }
  }, [setHideMessageContainer]);
  return (
    <div className="chat-page">
      <div className="chat-container">
        <div
          className="chat-list-container"
          style={{
            display: hide ? "none" : hideMessageContainer ? "block" : "block",
          }}
        >
          <div className="header">
            <nav.BackButton where="" />
            <h1>Messages</h1>
            <svg
              className="message-icon"
              width="18"
              height="18"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.577 20.3686H5.753C2.312 20.3686 0 17.9536 0 14.3596V6.04562C0 2.45162 2.312 0.0366211 5.753 0.0366211H9.492C9.906 0.0366211 10.242 0.372621 10.242 0.786621C10.242 1.20062 9.906 1.53662 9.492 1.53662H5.753C3.169 1.53662 1.5 3.30662 1.5 6.04562V14.3596C1.5 17.0986 3.169 18.8686 5.753 18.8686H14.577C17.161 18.8686 18.831 17.0986 18.831 14.3596V10.3316C18.831 9.91762 19.167 9.58162 19.581 9.58162C19.995 9.58162 20.331 9.91762 20.331 10.3316V14.3596C20.331 17.9536 18.018 20.3686 14.577 20.3686"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.86762 13.4282H9.84462C10.2246 13.4282 10.5806 13.2812 10.8496 13.0122L18.3586 5.50324C18.6666 5.19524 18.8366 4.78524 18.8366 4.34924C18.8366 3.91224 18.6666 3.50124 18.3586 3.19324L17.1416 1.97624C16.5046 1.34124 15.4686 1.34124 14.8306 1.97624L7.35762 9.44924C7.09862 9.70824 6.95162 10.0522 6.94262 10.4172L6.86762 13.4282ZM9.84462 14.9282H6.09862C5.89662 14.9282 5.70262 14.8462 5.56162 14.7012C5.42062 14.5572 5.34362 14.3622 5.34862 14.1592L5.44262 10.3802C5.46162 9.62824 5.76462 8.92124 6.29662 8.38824H6.29762L13.7706 0.915244C14.9926 -0.304756 16.9796 -0.304756 18.2016 0.915244L19.4186 2.13224C20.0116 2.72424 20.3376 3.51124 20.3366 4.34924C20.3366 5.18724 20.0106 5.97324 19.4186 6.56424L11.9096 14.0732C11.3586 14.6242 10.6246 14.9282 9.84462 14.9282V14.9282Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7308 7.9166C17.5388 7.9166 17.3468 7.8436 17.2008 7.6966L12.6348 3.1306C12.3418 2.8376 12.3418 2.3626 12.6348 2.0696C12.9278 1.7766 13.4018 1.7766 13.6948 2.0696L18.2608 6.6366C18.5538 6.9296 18.5538 7.4036 18.2608 7.6966C18.1148 7.8436 17.9228 7.9166 17.7308 7.9166"
                fill="black"
              />
            </svg>
          </div>
          <div className="wrapper">
            <div className="tab-bar">
              <ul>
                <NavLink to="/chat/">
                  <li>General Reachout</li>
                </NavLink>
                <NavLink to="/chat/starred">
                  <li>Starred Reachout</li>
                </NavLink>
              </ul>
            </div>
            <div className="chat-list">
              <util.MessagePreview />

            </div>
          </div>
        </div>

        <div
          className="message-container"
          style={{ display: hideMessageContainer ? "none" : "block" }}
        >
          <Outlet context={[setHide, setHideMessageContainer]} />
        </div>
      </div>
    </div>
  );
};

export default Chat;
