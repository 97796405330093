import { gql } from '@apollo/client'

const GET_USER_THREADS = gql`
  query getUserThreads($id: String!) {
    getUserThreads(id: $id) {
      id
      participants
      participantsInfo {
        firstName
        lastName
        id
        profilePicture
      }
      tailMessage {
        _id
        id
        senderId
        receiverId
        receivers
        type
        value
        threadId
        sentAt
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

export default GET_USER_THREADS
