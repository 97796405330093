//router
import { Link } from "react-router-dom";
//components
import { util, alert } from "../../../components";
//assets
import spinner from "../../../assets/spinner.svg";
//api
import { useQuery } from "@apollo/client";
import GET_REACHERS from "../../../apis/profile/getReachers";

const Reachers = () => {
  const { loading, error, data } = useQuery(GET_REACHERS, {
    variables: {
      page_limit: 100,
      page_number: 1,
    },
  });

  if (loading) {
    return (
      <div className="load-spinner">
        <img src={spinner} alt="spinner" />
      </div>
    );
  }
  return (
    <div className="reacher-container">
      {error && <alert.ErrorToast errorMessage="Failed to Fetch" />}
      {data &&
        data.getReachers.map((reacher) => {
          return (
            <Link
              to={`/u/${reacher.reacher.username}`}
              key={reacher.reacher.authId}
            >
              <util.ReachUser
                username={reacher.reacher.username}
                fullName={`${reacher.reacher.firstName} ${reacher.reacher.lastName}`}
                bio="Life na beans, shebi i don tell you before naaa"
                photo="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
              />
            </Link>
          );
        })}
    </div>
  );
};

export default Reachers;
