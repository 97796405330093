//react
import { useEffect } from "react";
//router
import { NavLink, useNavigate } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//api
import REGISTER_USER from "../apis/auth/AuthRegister";
//apollo
import { useMutation } from "@apollo/client";
//components
import { interact, alert } from "../components";

const Register = () => {
  const [createAccount, { loading, error, data }] = useMutation(REGISTER_USER);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if(data) {
        localStorage.setItem("user", JSON.stringify(data.createAccount));
        navigate(`/auth/activate`);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [data, navigate]);


  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
  
      const { firstName, lastName, email, password } = values;

      createAccount({
        variables: {
          'data': {
            'email': email,
            'firstName': firstName,
            'lastName': lastName,
            'password': password,
          },
        },
      });
    },
  });
  return (
    <div className="register">
      <svg
        width="24"
        height="24"
        viewBox="0 0 37 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3897_8920)">
          <path
            d="M36.3 32.7105C34.1776 33.6469 32.18 34.5208 30.1824 35.426C29.8702 35.582 29.6518 35.3948 29.4333 35.2699C27.6854 34.2399 25.9063 33.1787 24.1584 32.1487C16.3553 27.5293 8.52098 22.9098 0.717885 18.2904C0.187274 17.9783 0 17.6662 0.0312124 17.0419C0.0624248 15.5125 0.0312124 13.9831 0.0624248 12.4849C0.0624248 12.2352 0 11.9855 0.12485 11.7358C0.624248 11.8607 1.09243 12.1728 1.56062 12.4537C6.64824 15.4189 11.7359 18.3841 16.8235 21.3492C17.3541 21.6613 17.8535 21.7862 18.4465 21.755C19.9135 21.6613 21.3181 21.4116 22.6602 20.8498C25.4381 19.695 26.9987 17.6037 27.3108 14.6074C27.4357 13.5149 27.3733 12.4225 27.1548 11.3301C26.6554 8.92672 25.2508 7.21004 23.0347 6.14882C21.3181 5.30609 19.5077 5.02517 17.6662 4.99396C13.8271 4.96275 9.98796 4.99396 6.14884 4.99396C5.49338 4.99396 5.49338 4.99396 5.49338 5.68063C5.49338 6.99155 5.49338 8.27126 5.49338 9.58217C5.49338 9.83187 5.52459 10.0816 5.43095 10.3001C5.18125 10.3313 5.02519 10.1752 4.86913 10.0816C3.43336 9.27005 1.99759 8.45853 0.561823 7.64701C0.156062 7.45974 0 7.17882 0 6.74185C0.0312124 4.68184 0 2.62183 0.0312124 0.593025C0.0312124 0.0312029 0.0312124 -9.41893e-06 0.593035 -9.41893e-06C6.64824 -9.41893e-06 12.7347 -0.0312217 18.7899 0.0312029C21.3805 0.0624152 23.8775 0.655449 26.1872 1.87273C30.0887 3.93274 32.3048 7.17882 32.8354 11.5798C33.1475 14.2016 32.8354 16.761 31.7118 19.1644C30.3072 22.0983 27.9663 24.0335 25.0323 25.3132C24.8138 25.4068 24.6266 25.5005 24.4081 25.5941C24.3769 25.5941 24.3769 25.6253 24.3144 25.7189C28.2784 28.0287 32.2424 30.3384 36.3 32.7105Z"
            fill="#0C78B8"
          />
          <path
            d="M2.34079e-05 23.6902C8.2713 28.4657 16.3553 33.21 24.4705 37.923C24.4081 38.1415 24.252 38.1727 24.1272 38.2039C22.3481 39.0155 20.569 39.7958 18.8211 40.6073C18.4465 40.7946 18.1656 40.7633 17.7911 40.5449C12.048 37.1115 6.27371 33.6781 0.499421 30.2448C0.0936605 30.0263 -0.031189 29.7454 -0.031189 29.3084C0.0312358 27.5605 2.34079e-05 25.719 2.34079e-05 23.6902Z"
            fill="#0C78B8"
          />
        </g>
        <defs>
          <clipPath id="clip0_3897_8920">
            <rect width="36.3" height="40.7633" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <h1>Create an account</h1>
      <p className="register-desc">
        By continuing you agree to our User <NavLink to="/">Agreement</NavLink>{" "}
        and <NavLink to="/">Privacy Policy</NavLink>.
      </p>

      <form>
        {data && <alert.ErrorToast errorMessage="Account successfully created" color="#1C8B43" />}
        {error ? <alert.ErrorToast errorMessage={error.message} /> : null}

        <interact.Input
          type="text"
          placeholder="First Name"
          id="reg-firstName"
          name="firstName"
          change={formik.handleChange}
          blur={formik.handleBlur}
          value={formik.values.firstName}
        />
        {formik.touched.firstName && formik.errors.firstName ? (
          <div className="formik-error">Required</div>
        ) : null}

        <interact.Input
          type="text"
          placeholder="Last Name"
          id="reg-lastName"
          name="lastName"
          change={formik.handleChange}
          blur={formik.handleBlur}
          value={formik.values.lastName}
        />
        {formik.touched.lastName && formik.errors.lastName ? (
          <div className="formik-error">Required</div>
        ) : null}

        <interact.Input
          type="email"
          placeholder="Email"
          id="reg-email"
          name="email"
          change={formik.handleChange}
          blur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="formik-error">Invalid email</div>
        ) : null}

        <interact.PasswordInput
          placeholder="Password"
          id="reg-password"
          name="password"
          change={formik.handleChange}
          blur={formik.handleBlur}
          value={formik.values.password}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="formik-error">password required</div>
        ) : null}

        {/* <div className="or">
          <hr />
          <span>or</span>
        </div> */}

        {/* <button className="google">
          <svg width="20" height="20" viewBox="0 0 28 26" fill="none">
            <g clipPath="url(#clip0_3897_9746)">
              <path
                d="M27.5409 13.2306C27.5409 12.1664 27.4472 11.3898 27.2443 10.5845H14.0522V15.3877H21.7956C21.6396 16.5814 20.7965 18.379 18.9231 19.587L18.8968 19.7478L23.0679 22.7243L23.3569 22.7509C26.0109 20.493 27.5409 17.171 27.5409 13.2306Z"
                fill="#4285F4"
              />
              <path
                d="M14.0522 25.8861C17.8458 25.8861 21.0306 24.7356 23.3569 22.751L18.9231 19.5871C17.7366 20.3493 16.1441 20.8814 14.0522 20.8814C10.3365 20.8814 7.18295 18.6236 6.05879 15.5029L5.89401 15.5158L1.55684 18.6078L1.50012 18.753C3.81067 22.981 8.55671 25.8861 14.0522 25.8861Z"
                fill="#34A853"
              />
              <path
                d="M6.05853 15.5029C5.76191 14.6976 5.59025 13.8346 5.59025 12.9431C5.59025 12.0514 5.76191 11.1885 6.04293 10.3832L6.03507 10.2117L1.64355 7.07007L1.49987 7.13302C0.547584 8.88754 0.00115967 10.8578 0.00115967 12.9431C0.00115967 15.0283 0.547584 16.9985 1.49987 18.753L6.05853 15.5029Z"
                fill="#FBBC05"
              />
              <path
                d="M14.0522 5.0046C16.6905 5.0046 18.4703 6.05442 19.4851 6.93173L23.4505 3.36519C21.0151 1.27993 17.8458 0 14.0522 0C8.55671 0 3.81067 2.90497 1.50012 7.13299L6.04318 10.3832C7.18295 7.26247 10.3365 5.0046 14.0522 5.0046Z"
                fill="#EB4335"
              />
            </g>
            <defs>
              <clipPath id="clip0_3897_9746">
                <rect width="27.5554" height="25.9753" rx="8" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>Continue with Google</p>
        </button> */}

        <interact.Buttons
          loadState={loading}
          text="Done"
          type="submit"
          action={() => {
            formik.handleSubmit();
          }}
        />

        <p className="account">
          Already have an account? <NavLink to="/auth">Login</NavLink>
        </p>
      </form>
    </div>
  );
};

export default Register;
