import { gql } from "@apollo/client/core";

const SET_USERNAME = gql`
  mutation setUsername($username: String!) {
    setUsername(username: $username) {
      username
    }
  }
`;


export default SET_USERNAME;