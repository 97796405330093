import spinner from '../../assets/spinner.svg'

export const Spinner = () => {
      return (
            <div className="load-spinner">
                  <img
                        src={
                              spinner
                        }
                        alt="spinner"
                  />
            </div>
      )
}