//apis
import { useLazyQuery } from "@apollo/client";
import GET_SEARCH_PROFILE from "../../apis/profile/getSearchProfile";
//state
import { useState } from "react";
//components
import { util } from "../../components";
import { Input } from "@chakra-ui/react";
//router
import { Link } from "react-router-dom";
//assets
import spinner from "../../assets/spinner.svg";

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const [getAllUsers, { loading, data }] = useLazyQuery(GET_SEARCH_PROFILE);
  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="search-page">
      <nav className="search-nav">
        <Link to="/explore">
          <p className="cancel">Cancel</p>
        </Link>

        <div className="search-wrapper">
          <Input
            size="md"
            placeholder="Search Reachme"
            _placeholder={{ opacity: 0.5, color: "gray.500", fontSize: "sm" }}
            type="search"
            variant="filled"
            value={searchQuery}
            onChange={(e) => {
              onChange(e);
              if (e.target.value !== "") {
                getAllUsers({
                  variables: {
                    limit: 20,
                    name: e.target.value,
                    pageNumber: 1,
                  },
                });
              }
            }}
          />
        </div>
      </nav>

      {loading ? (
        <div className="load-spinner">
          <img src={spinner} alt="spinner" />
        </div>
      ) : (
        <div className="search">
          {data ? (
              data.searchProfile.length !== 0 ? (
                data.searchProfile.map((user) => {
                return (
                  <util.SearchedUser
                    key={user.username}
                    userName={user.username}
                    firstName={user.firstName}
                    lastName={user.lastName}
                  />
                );
              })
            ) : (
              <div className="no-users-found">
                <svg
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  color="#000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                  <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                </svg>
                <p>No users found</p>
              </div>
            )
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Search;
