import { gql } from "@apollo/client/core";

const GET_REACHINGS = gql`
  query ($page_limit: Int!, $page_number: Int!) {
    getReachings(page_limit: $page_limit, page_number: $page_number) {
      reaching {
        authId
        firstName
        lastName
        profilePicture
        username
      }
    }
  }
`;

export default GET_REACHINGS;
