//react
import { useEffect, useRef, useState } from "react";

//router
import { useOutletContext, useParams } from "react-router-dom";
//recoil
// import { useRecoilState } from "recoil";
// import UserData from "../../recoil/UserRecoil";

//api
import { useQuery, useMutation } from "@apollo/client";
import GET_THREAD_MESSAGES from "../../apis/messaging/getThreadMessages";
import SET_SEND_CHAT_MESSAGE from "../../apis/messaging/setSendChatMessage";
// import GET_USER_BY_EMAIL from "../../apis/profile/getUserByEmail";

//component
import { image, nav, util, animation } from "../../components";
import { Button, Avatar } from "@chakra-ui/react";

const IndividualChat = ({ showMyUrl }) => {
  let params = useParams();
  const [tempMessageList, setTempMessageList] = useState([]);

  //remove authId from here and use in the getThreadMessage query as a variable (user.authId) 
  // const [user] = useRecoilState(UserData);
  const [message, setMessage] = useState("");
  const [setHide, setHideMessageContainer] = useOutletContext();
  const messagesContainer = useRef(null);
  const inputField = useRef(null);

  // const { data: dataUser, loading: loadingUser, error: errorUser } = useQuery(GET_USER_BY_EMAIL, {
  //   variables: {
  //     prop: params.chatid
  //   }
  // })

  const [sendChatMessage, { data: dataSendingMessage, loading: loadingSendingMessage }] = useMutation(SET_SEND_CHAT_MESSAGE)

  const { data: dataMessages, error: errorMessages } = useQuery(GET_THREAD_MESSAGES, {
    variables: {
      id: `${params.chatid}--663f67ea-053d-4fb1-94da-de66fd1ef2a4`,
      fromMessageId: params.chatid,
    },
    pollInterval: 500,
  });


  useEffect(() => {
    messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
  })
  useEffect(() => {
    showMyUrl(params.chatid);
    if (window.screen.width <= 900) {
      setHide(true);
      setHideMessageContainer(false);
    }

  });
  useEffect(() => {
    if (dataSendingMessage) {
      setMessage("");
      inputField.current.innerHTML = "";
    }

    if (dataMessages) {
      setTempMessageList([...dataMessages.getThreadMessages]);
    }
  }, [dataSendingMessage, dataMessages]);



  return (
    <div className="individual-chat">
      <div className="header">
        <div className="avi">
          <nav.BackButton
            where="/chat"
            setHide={setHide}
            setHideMessageContainer={setHideMessageContainer}
          />
          <Avatar
            size="sm"
            src="" />
          <div className="span">
            <h2 className="username">Nelson Michael</h2>
            <p>Active about 45min ago</p>
          </div>
        </div>

        <div className="ctrls">
          <svg
            width="18"
            height="18"
            viewBox="0 0 27 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.3947 4.06467L24.3974 4.06326C24.6683 3.92544 24.9803 3.93874 25.2416 4.10336L25.2431 4.10429C25.5056 4.269 25.6667 4.55554 25.6667 4.88298V15.1178C25.6667 15.4459 25.5062 15.731 25.2439 15.896C25.0947 15.9891 24.9367 16.0319 24.784 16.0319C24.6564 16.0319 24.5253 16.0021 24.3971 15.9367L24.3931 15.9347L22.4206 14.9395C22.4203 14.9393 22.42 14.9392 22.4196 14.939C22.0299 14.7407 21.7839 14.344 21.7839 13.8887V6.11073C21.7839 5.65286 22.0308 5.25772 22.418 5.06264L22.4186 5.06229L24.3947 4.06467ZM13.2068 1C14.5866 1 15.6962 1.47158 16.4573 2.22297C17.2175 2.97343 17.6909 4.06276 17.6909 5.41455V14.5854C17.6909 15.9372 17.2175 17.0266 16.4573 17.777C15.6962 18.5284 14.5866 19 13.2068 19H5.48405C4.10427 19 2.99467 18.5284 2.23356 17.777C1.4734 17.0266 1 15.9372 1 14.5854V5.41455C1 4.06276 1.4734 2.97343 2.23356 2.22297C2.99467 1.47158 4.10427 1 5.48405 1H13.2068Z"
              stroke="#252525"
              strokeWidth="2"
            />
          </svg>

          <svg
            width="18"
            height="18"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.1741 11.4973C15.3731 15.6951 16.3257 10.8387 18.9993 13.5103C21.5768 16.0871 23.0582 16.6034 19.7925 19.8681C19.3835 20.1969 16.7845 24.1519 7.65079 15.0207C-1.48404 5.88842 2.46866 3.28678 2.79749 2.87784C6.07106 -0.395952 6.57843 1.09409 9.15593 3.67087C11.8295 6.34368 6.97505 7.29938 11.1741 11.4973Z"
              stroke="#252525"
              strokeWidth="2"
            />
          </svg>

          <svg
            width="8"
            height="18"
            viewBox="0 0 8 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.96606 12.6816C4.51835 12.6816 4.96606 12.2339 4.96606 11.6816C4.96606 11.1294 4.51835 10.6816 3.96606 10.6816C3.41378 10.6816 2.96606 11.1294 2.96606 11.6816C2.96606 12.2339 3.41378 12.6816 3.96606 12.6816Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.96606 5.68164C4.51835 5.68164 4.96606 5.23393 4.96606 4.68164C4.96606 4.12936 4.51835 3.68164 3.96606 3.68164C3.41378 3.68164 2.96606 4.12936 2.96606 4.68164C2.96606 5.23393 3.41378 5.68164 3.96606 5.68164Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.96606 19.6816C4.51835 19.6816 4.96606 19.2339 4.96606 18.6816C4.96606 18.1294 4.51835 17.6816 3.96606 17.6816C3.41378 17.6816 2.96606 18.1294 2.96606 18.6816C2.96606 19.2339 3.41378 19.6816 3.96606 19.6816Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      <div className="message-box">
        <div className="top" ref={messagesContainer}>
          <div className="message-box-header">
            <image.ImageFormatter
              source="https://images.unsplash.com/photo-1650649497336-68102b2940a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              width="70px"
              height="70px"
              alt="user profile"
            />
            <h3 className="fullname">Rooney Brown</h3>
            <p className="username">@RooneyBrown</p>
            <div className="audience">
              <span>
                <h3>2k</h3>
                <p>Reachers</p>
              </span>
              <span>
                <h3>270</h3>
                <p>Reaching</p>
              </span>
            </div>
            <p className="bio">
              English actor, typecast as the anithro, Born in shirebrook,
              Derbyshire.
            </p>
            <Button
              className="edit-profile-btn"
              variant="solid"
              size="sm"
            // onClick={reachUserHandler}
            // isLoading={loading ? true : false}
            >
              View Profile
            </Button>
          </div>
          <div className="messages">
            {errorMessages ? <p className="error-loading-messages">Couldn't retrieve messages!</p> : (
              tempMessageList.map((message, index) => {
                return (
                  <animation.FadeIn key={index}>
                    <util.Message content={message.value} position={params.chatid !== message.receiverId ? true : false} />
                  </animation.FadeIn>
                )
              })
            )}
            {loadingSendingMessage && <p className="message-sending">Sending...</p>}
          </div>
        </div>

        <div className="bottom">
          <div className="chat-input">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8047 0.582031C5.45187 0.582031 0.304688 5.50542 0.304688 11.582C0.304688 17.6586 5.45187 22.582 11.8047 22.582C18.1575 22.582 23.3047 17.6586 23.3047 11.582C23.3047 5.50542 18.1575 0.582031 11.8047 0.582031ZM15.5144 8.03364C16.3351 8.03364 16.9982 8.66792 16.9982 9.453C16.9982 10.2381 16.3351 10.8724 15.5144 10.8724C14.6936 10.8724 14.0305 10.2381 14.0305 9.453C14.0305 8.66792 14.6936 8.03364 15.5144 8.03364ZM8.09501 8.03364C8.91578 8.03364 9.57888 8.66792 9.57888 9.453C9.57888 10.2381 8.91578 10.8724 8.09501 10.8724C7.27424 10.8724 6.61114 10.2381 6.61114 9.453C6.61114 8.66792 7.27424 8.03364 8.09501 8.03364ZM17.1281 15.5828C15.8065 17.0998 13.8682 17.9691 11.8047 17.9691C9.74118 17.9691 7.80287 17.0998 6.4813 15.5828C5.85065 14.8599 6.99138 13.955 7.62203 14.6736C8.66074 15.8667 10.1817 16.5453 11.8047 16.5453C13.4277 16.5453 14.9486 15.8623 15.9873 14.6736C16.6087 13.955 17.7541 14.8599 17.1281 15.5828Z"
                fill="#FFA800"
              />
            </svg>

            <div
              title="Type message..."
              role="textbox"
              className="input"
              contentEditable="true"
              dir="ltr"
              spellCheck="true"
              ref={inputField}
              onKeyDown={(e) => {
                setMessage(e.target.innerHTML);
              }}
            ></div>
            {message ? (
              <animation.FadeIn>
                <svg onClick={() => {
                  if (message) {
                    setTempMessageList([...tempMessageList, { value: message, receiverId: params.chatid }]);
                    sendChatMessage({
                      variables: {
                        "senderId": "663f67ea-053d-4fb1-94da-de66fd1ef2a4",
                        "receiverId": params.chatid,
                        "type": "text",
                        "threadId": `${params.chatid}--663f67ea-053d-4fb1-94da-de66fd1ef2a4`,
                        "value": message
                      }
                    })
                  }
                }} width="28" height="28" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24.7219" cy="25.1398" r="24.7219" fill="#0077B6" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M19.5527 26.2033L17.5437 34.6683C17.4555 35.039 17.6788 35.2571 17.7803 35.3339C17.8818 35.4107 18.151 35.5704 18.4891 35.3877L36.1588 25.7911C36.4704 25.6207 36.5048 25.3268 36.5048 25.2103C36.5066 25.0955 36.4695 24.8095 36.1694 24.6418L18.5315 14.994C18.1969 14.8113 17.9233 14.9719 17.8218 15.0487C17.7185 15.1273 17.4899 15.3488 17.5746 15.7213L19.5589 24.3311L28.9684 24.2817C29.4848 24.279 29.9067 24.6957 29.9102 25.2129C29.9138 25.7302 29.4954 26.1521 28.979 26.1548L19.5527 26.2033ZM16.3821 36.5944C15.7677 35.98 15.5171 35.1044 15.7218 34.2358L17.8527 25.2606L15.751 16.1415C15.525 15.1582 15.8834 14.1678 16.6902 13.557C17.4961 12.9453 18.5456 12.8685 19.4292 13.3513L37.0671 22.9991C37.8783 23.4431 38.377 24.2896 38.3788 25.2129C38.3761 26.1406 37.8686 26.9924 37.0512 27.4355L19.3824 37.0313C18.4944 37.515 17.4449 37.4347 16.6434 36.8221C16.5498 36.7497 16.4633 36.6756 16.3821 36.5944Z" fill="white" />
                </svg>
              </animation.FadeIn>
            ) : (
              <animation.FadeIn>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0719 0.582031C19.8001 0.582031 22.3047 3.19796 22.3047 7.09042V16.0736C22.3047 19.9661 19.8001 22.582 16.0708 22.582H6.53749C2.80927 22.582 0.304688 19.9661 0.304688 16.0736V7.09042C0.304688 3.19796 2.80927 0.582031 6.53749 0.582031H16.0719ZM17.2849 12.1871C16.1058 11.4515 15.1955 12.4845 14.9499 12.8148C14.7132 13.1338 14.5096 13.4857 14.2951 13.8377C13.7708 14.7061 13.1701 15.7073 12.1304 16.2897C10.6194 17.1263 9.47231 16.3555 8.64714 15.7947C8.33742 15.5858 8.03655 15.3883 7.73679 15.2566C6.99789 14.9377 6.3331 15.3009 5.34643 16.5542C4.82876 17.2092 4.31551 17.8585 3.79563 18.5056C3.48481 18.8927 3.55892 19.4898 3.97814 19.7486C4.64735 20.1607 5.46368 20.382 6.3862 20.382H15.6567C16.1799 20.382 16.7042 20.3105 17.2042 20.147C18.3302 19.7792 19.224 18.9369 19.6908 17.8244C20.0846 16.889 20.2759 15.7539 19.9076 14.8094C19.7848 14.4961 19.6012 14.2043 19.3435 13.9478C18.6676 13.2769 18.036 12.6502 17.2849 12.1871ZM7.45343 4.98203C5.93692 4.98203 4.70469 6.21593 4.70469 7.73203C4.70469 9.24813 5.93692 10.482 7.45343 10.482C8.96884 10.482 10.2022 9.24813 10.2022 7.73203C10.2022 6.21593 8.96884 4.98203 7.45343 4.98203Z"
                    fill="#CD00EE"
                  />
                </svg>
              </animation.FadeIn>
            )}

          </div>

          <svg
            className="mic-btn"
            width="30"
            height="30"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25.2785" cy="25.2512" r="24.7219" fill="#0077B6" />
            <g clipPath="url(#clip0_2983_1990)">
              <path
                d="M25.279 11.2441C24.2659 11.2441 23.2942 11.6466 22.5778 12.363C21.8614 13.0794 21.459 14.051 21.459 15.0641V25.2508C21.459 26.2639 21.8614 27.2356 22.5778 27.952C23.2942 28.6683 24.2659 29.0708 25.279 29.0708C26.2921 29.0708 27.2637 28.6683 27.9801 27.952C28.6965 27.2356 29.099 26.2639 29.099 25.2508V15.0641C29.099 14.051 28.6965 13.0794 27.9801 12.363C27.2637 11.6466 26.2921 11.2441 25.279 11.2441V11.2441Z"
                fill="white"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M34.1919 22.7041V25.2508C34.1919 27.6147 33.2528 29.8819 31.5812 31.5534C29.9097 33.225 27.6425 34.1641 25.2786 34.1641C22.9146 34.1641 20.6475 33.225 18.9759 31.5534C17.3043 29.8819 16.3652 27.6147 16.3652 25.2508V22.7041"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.2773 34.1641V39.2574"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.1855 39.2578H30.3722"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2983_1990">
                <rect
                  width="30.56"
                  height="30.56"
                  fill="white"
                  transform="translate(9.99805 9.9707)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default IndividualChat;
