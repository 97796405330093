import { Outlet } from "react-router-dom";
import Login from "../../containers/Login";
const Auth = () => {
  return (
    <>
      <div className="login wrapper">
        <div className="left">
          <svg
            width="90"
            height="90"
            viewBox="0 0 130 145"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3897_8913)">
              <path
                d="M129.123 116.355C121.574 119.686 114.468 122.795 107.362 126.014C106.252 126.57 105.475 125.903 104.698 125.459C98.4802 121.795 92.1517 118.021 85.9343 114.357C58.1777 97.9249 30.3102 81.493 2.5536 65.0612C0.666157 63.9509 0 62.8406 0.111026 60.6201C0.222052 55.1799 0.111026 49.7396 0.222052 44.4103C0.222052 43.5221 0 42.6339 0.444105 41.7457C2.22052 42.1898 3.88592 43.3001 5.55131 44.2993C23.6486 54.8468 41.7459 65.3942 59.8431 75.9417C61.7306 77.052 63.507 77.4961 65.6165 77.3851C70.8347 77.052 75.8309 76.1638 80.605 74.1653C90.4864 70.0573 96.0377 62.6186 97.1479 51.9601C97.592 48.0742 97.37 44.1883 96.5928 40.3024C94.8164 31.7534 89.8202 25.6469 81.9373 21.872C75.8309 18.8743 69.3914 17.8751 62.8408 17.7641C49.1846 17.6531 35.5284 17.7641 21.8722 17.7641C19.5406 17.7641 19.5406 17.7641 19.5406 20.2067C19.5406 24.8698 19.5406 29.4218 19.5406 34.0849C19.5406 34.9731 19.6516 35.8613 19.3186 36.6385C18.4303 36.7495 17.8752 36.1944 17.3201 35.8613C12.2129 32.9747 7.10568 30.088 1.99847 27.2013C0.555131 26.5351 0 25.5359 0 23.9815C0.111026 16.6538 0 9.32611 0.111026 2.10942C0.111026 0.11095 0.111026 -7.55284e-05 2.1095 -7.55284e-05C23.6486 -7.55284e-05 45.2987 -0.111102 66.8378 0.11095C76.0529 0.221977 84.9351 2.33147 93.151 6.66149C107.029 13.9892 114.912 25.5359 116.8 41.1906C117.91 50.5168 116.8 59.6209 112.803 68.1699C107.806 78.6063 99.4795 85.49 89.043 90.042C88.2658 90.3751 87.5997 90.7082 86.8225 91.0413C86.7115 91.0413 86.7115 91.1523 86.4894 91.4854C100.59 99.7013 114.69 107.917 129.123 116.355Z"
                fill="white"
              />
              <path
                d="M3.21865e-06 84.2688C29.4219 101.256 58.1777 118.132 87.0445 134.897C86.8225 135.674 86.2673 135.785 85.8232 135.896C79.4947 138.783 73.1662 141.558 66.9488 144.445C65.6165 145.111 64.6172 145 63.2849 144.223C42.8561 132.01 22.3163 119.797 1.77642 107.584C0.333082 106.807 -0.111023 105.808 -0.111023 104.254C0.111029 98.036 3.21865e-06 91.4855 3.21865e-06 84.2688Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_3897_8913">
                <rect width="129.123" height="145" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="right">
          <svg
            width="24"
            height="24"
            viewBox="0 0 37 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_3897_8920)">
              <path
                d="M36.3 32.7105C34.1776 33.6469 32.18 34.5208 30.1824 35.426C29.8702 35.582 29.6518 35.3948 29.4333 35.2699C27.6854 34.2399 25.9063 33.1787 24.1584 32.1487C16.3553 27.5293 8.52098 22.9098 0.717885 18.2904C0.187274 17.9783 0 17.6662 0.0312124 17.0419C0.0624248 15.5125 0.0312124 13.9831 0.0624248 12.4849C0.0624248 12.2352 0 11.9855 0.12485 11.7358C0.624248 11.8607 1.09243 12.1728 1.56062 12.4537C6.64824 15.4189 11.7359 18.3841 16.8235 21.3492C17.3541 21.6613 17.8535 21.7862 18.4465 21.755C19.9135 21.6613 21.3181 21.4116 22.6602 20.8498C25.4381 19.695 26.9987 17.6037 27.3108 14.6074C27.4357 13.5149 27.3733 12.4225 27.1548 11.3301C26.6554 8.92672 25.2508 7.21004 23.0347 6.14882C21.3181 5.30609 19.5077 5.02517 17.6662 4.99396C13.8271 4.96275 9.98796 4.99396 6.14884 4.99396C5.49338 4.99396 5.49338 4.99396 5.49338 5.68063C5.49338 6.99155 5.49338 8.27126 5.49338 9.58217C5.49338 9.83187 5.52459 10.0816 5.43095 10.3001C5.18125 10.3313 5.02519 10.1752 4.86913 10.0816C3.43336 9.27005 1.99759 8.45853 0.561823 7.64701C0.156062 7.45974 0 7.17882 0 6.74185C0.0312124 4.68184 0 2.62183 0.0312124 0.593025C0.0312124 0.0312029 0.0312124 -9.41893e-06 0.593035 -9.41893e-06C6.64824 -9.41893e-06 12.7347 -0.0312217 18.7899 0.0312029C21.3805 0.0624152 23.8775 0.655449 26.1872 1.87273C30.0887 3.93274 32.3048 7.17882 32.8354 11.5798C33.1475 14.2016 32.8354 16.761 31.7118 19.1644C30.3072 22.0983 27.9663 24.0335 25.0323 25.3132C24.8138 25.4068 24.6266 25.5005 24.4081 25.5941C24.3769 25.5941 24.3769 25.6253 24.3144 25.7189C28.2784 28.0287 32.2424 30.3384 36.3 32.7105Z"
                fill="#0C78B8"
              />
              <path
                d="M2.34079e-05 23.6902C8.2713 28.4657 16.3553 33.21 24.4705 37.923C24.4081 38.1415 24.252 38.1727 24.1272 38.2039C22.3481 39.0155 20.569 39.7958 18.8211 40.6073C18.4465 40.7946 18.1656 40.7633 17.7911 40.5449C12.048 37.1115 6.27371 33.6781 0.499421 30.2448C0.0936605 30.0263 -0.031189 29.7454 -0.031189 29.3084C0.0312358 27.5605 2.34079e-05 25.719 2.34079e-05 23.6902Z"
                fill="#0C78B8"
              />
            </g>
            <defs>
              <clipPath id="clip0_3897_8920">
                <rect width="36.3" height="40.7633" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <h1>Welcome to Reachme</h1>
          <p>
            Connect to your world, reach friends and family, chat in diverse
            languages, provide meanings to words and bring your culture to
            light.
          </p>

          <Login />
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Auth;
