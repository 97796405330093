import { interact } from "../../components";
import Reset from "../../containers/Reset";

const ResetPassword = () => {
  return (
    <div className="forgot-password-container wrapper">
      <div className="form-container">
        <interact.CloseButton where="auth" />
        <Reset />
      </div>
    </div>
  );
};

export default ResetPassword;
