import { gql } from "@apollo/client";
const LIKE_POST = gql`
mutation($postId: String!){
  likePost(postId: $postId){
    authId
  }
}
`;

export default LIKE_POST;
