//react
import { useEffect, useState } from "react";
//apollo
import { useQuery } from "@apollo/client";
//apis
import GET_USER_BY_USERNAME from "../../../apis/profile/getUserByUsername";
//router
import { Outlet, NavLink, Link, useParams } from "react-router-dom";
//components
import { nav, util } from "../../../components";
//assets
import spinner from "../../../assets/spinner.svg";

const AudienceContainer = () => {
  let params = useParams();
  const [userProfile, setUserProfile] = useState("");
  const { firstName, lastName, nReachers, nReaching, nStaring, username } =
    userProfile;

  const { data: currentUser, loading: userLoading } = useQuery(
    GET_USER_BY_USERNAME,
    {
      variables: { username: params.username },
    }
  );

  useEffect(() => {
    if (currentUser) {
      setUserProfile(currentUser.getUserByUsername[0]);
    }
  }, [currentUser]);
  return (
    <>
      <div className="audience-page">
        <div className="audience-container">
          <div className="audience-main">
            <nav>
              <div className="navigate">
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 22 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.19231 1.61539L1.5 7.30769L7.19231 13"
                    stroke="#252525"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.5 7.30762L20 7.30762"
                    stroke="#252525"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Link to={`/u/${username}`}>
                  <p>{currentUser ? `${firstName} ${lastName}` : ""}</p>
                </Link>
              </div>

              <ul>
                <NavLink to={`/u/${username}/audience/`}>
                  <li>{nReachers} Reacher</li>
                </NavLink>
                <NavLink to={`/u/${username}/audience/reaching`}>
                  <li>{nReaching} Reaching</li>
                </NavLink>
                <NavLink to={`/u/${username}/audience/star`}>
                  <li>{nStaring} Star</li>
                </NavLink>
              </ul>
            </nav>
            {userLoading ? (
              <div className="load-spinner">
                <img src={spinner} alt="spinner" />
              </div>
            ) : (
              <Outlet context={userProfile} />
            )}
          </div>
          <aside>
            <div className="reach-suggestion-container">
              <header>
                <h2>Reach Suggested for</h2>
              </header>
              <div className="wrapper">
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                  name="Rooney Brown"
                  info="New to reach me"
                />
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                  name="Ragna Lothbrok"
                  info="Reached by Stanchiqa"
                />
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1503235930437-8c6293ba41f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  name="Bjorn Lothbrok"
                  info="New to reach me"
                />
                <util.ReachSuggestion
                  photo="https://images.unsplash.com/photo-1533636721434-0e2d61030955?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                  name="Karl Djurn"
                  info="Reaches you"
                />
              </div>
            </div>
          </aside>
        </div>
      </div>
      <nav.BottomNav />
    </>
  );
};

export default AudienceContainer;
