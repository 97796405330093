//components
import { util } from "../../components";

const AllNotifications = () => {
  return (
    <div className="all-notifications-container">
      <util.ReachUser
        username="jake"
        fullName="Nelson Michael"
        bio="Life na beans, shebi i don tell you before naaa"

      />
    </div>
  );
};

export default AllNotifications;
