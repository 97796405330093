//components
import { animation, util, interact } from "..";
import { Avatar } from "@chakra-ui/react"
//assets
export const MiniUserProfile = ({ dataGetUser, loadingGetUser, post, user, setShowViewProfile }) => {

      return (
            <animation.FadeIn>
                  <div
                        className="view-user-profile"
                        onMouseOver={() => {
                              setShowViewProfile(
                                    true
                              );
                        }}
                        onMouseLeave={() => {
                              setShowViewProfile(
                                    false
                              );
                        }}
                  >
                        {loadingGetUser ? (
                              <util.Spinner />
                        ) : (
                              <div>
                                    <div className="header-info">
                                          <div className="profile">
                                                <Avatar
                                                            src={dataGetUser.getUserByUsername[0].profilePicture}
                                                      size="sm"
                                                />
                                                <span>
                                                      <h2>
                                                            {dataGetUser && `${dataGetUser.getUserByUsername[0].firstName} ${dataGetUser.getUserByUsername[0].lastName}`}
                                                      </h2>
                                                      <p>
                                                            Manchester,
                                                            United
                                                            Kingdom
                                                      </p>
                                                </span>
                                          </div>

                                          {user.authId !== post.post.authId ? (<interact.ReachButton userId={dataGetUser && dataGetUser.getUserByUsername[0].authId} />) : null}

                                    </div>
                                    <div className="bio">
                                          <p>
                                                {dataGetUser ? `${dataGetUser.getUserByUsername[0].bio} ` : " "}
                                          </p>
                                    </div>
                                    <div className="audience">
                                          <span>
                                                <p>
                                                      {dataGetUser && `${dataGetUser.getUserByUsername[0].nReachers}`}
                                                </p>
                                                <p>
                                                      Reacher
                                                </p>
                                          </span>
                                          <span>
                                                <p>
                                                      {dataGetUser && `${dataGetUser.getUserByUsername[0].nReaching}`}
                                                </p>
                                                <p>
                                                      Reaching
                                                </p>
                                          </span>
                                          <span>
                                                <p>
                                                      {dataGetUser && `${dataGetUser.getUserByUsername[0].nStaring}`}
                                                </p>
                                                <p>
                                                      Star
                                                </p>
                                          </span>
                                    </div>
                              </div>
                        )}
                  </div>
            </animation.FadeIn>
      )
}